import React from "react";
import Logo_W from "../data/logo/Logo_PD_W.svg";
import { Link } from "react-router-dom";
import FooterPortugal2020 from "../data/logo/Logo_Portugal_2020_Cores.jpg";
import Norte2020 from "../data/logo/Norte_2020_Cores.jpg";
import EU_Logo from "../data/logo/Asset3.svg";
import Down_Arrow from "../data/Icons/Down_Arrow_black.svg";

function Work(props) {
  window.scrollTo(0, 0);
  return (
    <>
      <nav
        id="nav"
        className="flex justify-between py-10 items-top"
        style={{ paddingLeft: "300px", paddingRight: "150px" }}
      >
        <div>
          <Link to="/">
            <img src={Logo_W} alt="Logo" style={{ width: "200px" }} />
          </Link>
        </div>
        <div>
          <ul className="nav_ul">
            <li
              className="text-white"
              style={{ fontSize: "18px", fontWeight: "bold" }}
            >
              <Link to="/Work">WORK</Link>
            </li>
            <li
              className="text-white inactive"
              style={{ fontSize: "18px", fontWeight: "bold" }}
            >
              <Link to="/Theory">THEORY</Link>
            </li>
            <li
              className="text-white inactive"
              style={{ fontSize: "18px", fontWeight: "bold" }}
            >
              <Link to="/About">ABOUT</Link>
            </li>
            <li
              className="text-white inactive"
              style={{ fontSize: "18px", fontWeight: "bold" }}
            >
              <Link to="/Awards">AWARDS</Link>
            </li>
            <li
              className="text-white inactive"
              style={{ fontSize: "18px", fontWeight: "bold" }}
            >
              <Link to="/Contacts">CONTACTS</Link>
            </li>
            <li
              className="text-white"
              style={{
                fontWeight: "normal",
                fontSize: "11px",
                fontKerning: "-10px",
                opacity: "50%",
              }}
            >
              <p>Language</p>
              <p>English</p>
            </li>
          </ul>
        </div>
      </nav>

      <main style={{ marginTop: "7em" }} className="white__bg">
        <div className="container">
          <div className="container__nav">
            <div className="nav-grid">
              <div className="filter">
                <p className="filter__text active">All projects</p>
              </div>
              <div className="filter">
                <p className="filter__text">Branding</p>
              </div>
              <div className="filter">
                <p className="filter__text">Digital</p>
              </div>
              <div className="filter">
                <p className="filter__text">Editorial</p>
              </div>
              <div className="filter">
                <p className="filter__text">Environment</p>
              </div>
              <div className="filter">
                <p className="filter__text">Museography</p>
              </div>
              <div className="filter">
                <p className="filter__text">Product</p>
              </div>
              <div className="filter">
                <p className="filter__text">Signage</p>
              </div>
            </div>
          </div>
          <div className="project-grid">
            {/* <div className="card">
              <img src={project} alt="" className="card__img" />
            </div>
            <div className="card">
              <img src={project2} alt="" className="card__img" />
            </div>
            <div className="card">
              <img src={project3} alt="" className="card__img" />
            </div>
            <div className="card">
              <img src={project4} alt="" className="card__img" />
            </div>
            <div className="card">
              <img src={project5} alt="" className="card__img" />
            </div>
            <div className="card">
              <img src={project6} alt="" className="card__img" />
            </div>
            <div className="card">
              <img src={project7} alt="" className="card__img" />
            </div>
            <div className="card">
              <img src={project8} alt="" className="card__img" />
            </div>
            <div className="card">
              <img src={project} alt="" className="card__img" />
            </div>
            <div className="card">
              <img src={project2} alt="" className="card__img" />
            </div>
            <div className="card">
              <img src={project3} alt="" className="card__img" />
            </div>
            <div className="card">
              <img src={project4} alt="" className="card__img" />
            </div>
            <div className="card">
              <img src={project5} alt="" className="card__img" />
            </div>
            <div className="card">
              <img src={project6} alt="" className="card__img" />
            </div>
            <div className="card">
              <img src={project7} alt="" className="card__img" />
            </div>
            <div className="card">
              <img src={project8} alt="" className="card__img" />
            </div> */}
          </div>
        </div>
        <div>
          <h1
            style={{
              height: "100%",
              color: "black",
              fontWeight: "bold",
              fontSize: "18px",
              opacity: "40%",
              textAlign: "center",
              margin: "auto",
              marginTop: "1em",
            }}
          >
            LOAD MORE PROJECTS
          </h1>
          <img
            src={Down_Arrow}
            alt="Down_Arrow"
            style={{
              width: "17px",
              paddingTop: ".5em",
              textAlign: "center",
              margin: "auto",
            }}
          ></img>
        </div>
      </main>

      <footer
        className="flex justify-between px-20 items-center white__bg"
        style={{
          paddingLeft: "300px",
          paddingRight: "300px",
          backgroundColor: "black",
          paddingTop: "5em",
          paddingBottom: "2.5em",
        }}
      >
        <div className="flex items-center">
          <ul className="flex items-center space-x-10">
            <li className="text-black">
              <div>
                <img src={Norte2020} alt="Logo" style={{ height: "2em" }}></img>
              </div>
            </li>
            <li className="text-black">
              <div>
                <img
                  src={FooterPortugal2020}
                  alt="Logo"
                  style={{ height: "2em" }}
                ></img>
              </div>
            </li>
            <li className="text-black">
              <div>
                <img src={EU_Logo} alt="Logo" style={{ height: "2em" }}></img>
              </div>
            </li>
          </ul>
        </div>
        <div className="flex items-center">
          <ul className="flex items-end space-x-20 justify-between">
            <li className="text-black">
              <div>
                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "14px",
                    opacity: "50%",
                  }}
                >
                  © 1985 – 2023
                </p>
                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "14px",
                    opacity: "50%",
                  }}
                >
                  ProvidênciaDesign
                </p>
              </div>
            </li>
            <li className="text-black">
              <div>
                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "14px",
                    opacity: "50%",
                  }}
                >
                  Behance
                </p>
              </div>
            </li>
            <li className="text-black">
              <div>
                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "14px",
                    opacity: "50%",
                  }}
                >
                  Vimeo
                </p>
              </div>
            </li>
            <li className="text-black">
              <div>
                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "14px",
                    opacity: "50%",
                  }}
                >
                  LinkedIn
                </p>
              </div>
            </li>
            <li className="text-black">
              <div>
                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "14px",
                    opacity: "50%",
                  }}
                >
                  Instagram
                </p>
              </div>
            </li>
            <li className="text-black">
              <div>
                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "14px",
                    opacity: "50%",
                  }}
                >
                  Facebook
                </p>
              </div>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
}

export default Work;
