import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import loadingLogo from "../data/logo/Logo_PD_B.svg";

const TeamItem = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const resetGridStyles = () => {
    // Get all items in the grid and reset their styles
    const gridItems = document.querySelectorAll(".team-grid-item");
    gridItems.forEach((item) => {
      if (item.style) {
        item.style.gridColumn = "";
        item.style.gridRow = "";
      }
    });
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      // Check if the clicked element is not within the current item
      if (!event.target.closest(".team__item")) {
        // Reset styles for all grid items
        resetGridStyles();
      }
    };

    document.body.addEventListener("click", handleDocumentClick);

    return () => {
      document.body.removeEventListener("click", handleDocumentClick);
    };
  }, [resetGridStyles]);


  const handleClickDesktop = (event) => {
    resetGridStyles();

    const item = event.currentTarget;
    const index = Array.from(item.parentNode.children).indexOf(item);
    const rowIndex = Math.floor(index / 4) + 1; // Calculate the row index
    const colIndex = (index % 4) + 1; // Calculate the column index

    // Add your hover effect here
    if (colIndex === 1) {
      item.style.gridColumn = "1 / 3";
    } else if (colIndex === 2) {
      item.style.gridColumn = "2 / 4";
    } else if (colIndex === 3) {
      item.style.gridColumn = "2 / 4";
    } else if (colIndex === 4) {
      item.style.gridColumn = "3 / 5";
    }

    // Set grid-rows dynamically based on the row index
    item.style.gridRow = `${rowIndex} / ${rowIndex + 2}`;
  };

  const handleMouseOut = (event) => {
    const item = event.currentTarget;
    if (item.style) {
      item.style.gridColumn = "";
      item.style.gridRow = ""; // Reset the grid-row style
    }
  };

  return (
    <motion.div
      className="team__item"
      // onHoverStart={handleHover}
      // onHoverEnd={handleMouseLeave}
      key={props.data.id}
      // onMouseOver={handleGridHover}
      // onMouseOut={handleMouseOut}
      onClick={handleClickDesktop}
      whileHover={{ opacity: 1 }}
    >
      {/* <Link to={`/TeamInfo/${props.data.url}`} state={props.data}> */}
        <motion.img
          src={
            isImageLoaded
              ? `https://providencia-design-bucket.s3.eu-west-2.amazonaws.com/${props.data.avatar}`
              : loadingLogo
          }
          alt="TeamMemberImage"
          className="card__img blurTeste"
          animate={{
            opacity: isImageLoaded ? 1 : 0,
            filter: isImageLoaded ? "blur(0px)" : "blur(8px)",
          }}
          transition={{ duration: 0.5 }}
          onLoad={handleImageLoad}
        />
        <motion.div
          className="team-title"
          initial={{ opacity: 0 }}
          animate={{
            opacity: isHovered ? 1 : 0,
          }}
          transition={{ duration: 0.3 }}
        >
          {props.data.name}
        </motion.div>
      {/* </Link> */}
    </motion.div>
  );
};

export default TeamItem;
