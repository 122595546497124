import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function AdminProjectItem(props) {
  const [boolean, setBoolean] = useState(props.data.featured);
  const [boolean2, setBoolean2] = useState(props.data.favorite);
  const [tempData, setTempData] = useState(props.data);
  const [tempData2, setTempData2] = useState(props.data);

  const navigate = useNavigate();

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const featured = () => {
    axios.post("https://api.fprovidencia.com/project/featured", tempData);
    if (tempData.featured === true) {
      alert("Projeto Removido aos Destaques");
    } else {
      alert("Projeto Adicionado aos Destaques");
    }
    axios
      .get(`https://api.fprovidencia.com/project/getOne/${props.data._id}`)
      .then(function (response) {
        setBoolean(response.data.featured);
        setTempData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const favorite = () => {
    axios.post("https://api.fprovidencia.com/project/favorite", tempData2);
    if (tempData2.favorite === true) {
      alert("Projeto Removido dos Favoritos");
    } else {
      alert("Projeto Adicionado aos Favoritos");
    }
    axios
      .get(`https://api.fprovidencia.com/project/getOne/${props.data._id}`)
      .then(function (response) {
        setBoolean2(response.data.favorite);
        setTempData2(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteOne = async () => {
    if (window.confirm("Delete Project?")) {
      await axios.post(
        `https://api.fprovidencia.com/project/deleteOne/${props.data._id}`
      );
      window.location.reload();
    } else {
      console.log("No");
    }
  };

  return (
    <div key={props.index} className="Admin__Project__Item">
      <div className="Admin__Project__Item__Thumbnail">
        <img
          className="Admin__Project__Item__Img"
          src={`https://providencia-design-bucket.s3.eu-west-2.amazonaws.com/${props.data.capa}`}
        />
        {currentLanguage === "pt" ? (
          <p className="Admin__Project__Item__Title">{props.data.title.pt}</p>
        ) : (
          <p className="Admin__Project__Item__Title">{props.data.title.en}</p>
        )}
        <div className="Admin__Project__Item__Icons">
          <button className="Admin__Project__Item__Action" onClick={deleteOne}>
            <AiOutlineDelete />
          </button>
          <Link
            to={`/Admin/Dashboard/EditProject/${props.data._id}`}
            className="Admin__Project__Item__Action"
          >
            <AiOutlineEdit />
          </Link>

          <button className="Admin__Project__Item__Action" onClick={favorite}>
            {boolean2 ? <AiFillHeart /> : <AiOutlineHeart />}
          </button>

          <button className="Admin__Project__Item__Action" onClick={featured}>
            {boolean ? <AiFillStar /> : <AiOutlineStar />}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminProjectItem;
