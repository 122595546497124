import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { Link } from "react-router-dom";

function AdminTeamItem(props) {
  const deleteOne = async () => {
    if (window.confirm("Delete Team Member?")) {
      await axios.post(
        `https://api.fprovidencia.com/team/deleteOne/${props.data._id}`
      );
      window.location.reload();
    } else {
      console.log("No");
    }
  };

  return (
    <div key={props.index} className="Admin__Project__Item">
      <div className="Admin__Project__Item__Thumbnail">
        <img
          className="Admin__Project__Item__Img"
          src={`https://providencia-design-bucket.s3.eu-west-2.amazonaws.com/${props.data.avatar}`}
        />
        <p>{props.data.name}</p>
        <div className="Admin__Project__Item__Icons">
          <button className="Admin__Project__Item__Action" onClick={deleteOne}>
            <AiOutlineDelete />
          </button>
          <Link
            className="Admin__Project__Item__Action"
            to={`/Admin/Dashboard/EditTeamMember/${props.data._id}`}
            style={{ cursor: "pointer" }}
          >
            <AiOutlineEdit />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AdminTeamItem;
