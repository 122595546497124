import React, { useState, useEffect } from "react";
import "../css/Theory.css";
import {
  motion,
  useAnimate,
  useScroll,
  useMotionValueEvent,
} from "framer-motion";
import { useNavigate } from "react-router-dom";
import TheoryTriangle from "../data/TheoryTriangle";

import NavBar from "./NavBar";

function Theory_Brief(props) {
  const navigate = useNavigate();

  const [scope, animate] = useAnimate();

  const { scrollY } = useScroll();

  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    setHidden(true);
  }, []);

  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious();
    if (latest > previous) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <style>
        {`
          /* For Webkit browsers (Chrome, Safari) */
          *::-webkit-scrollbar {
            width: 0px!important; /* Adjust as needed */
          }

          *::-webkit-scrollbar {
            display: none;
          }

          *{
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */

          *::-webkit-scrollbar-thumb {
            background-color: #000; /* Color of the scrollbar thumb */
          }

          *::-webkit-scrollbar-track {
            background-color: #000; /* Color of the scrollbar track */
          }

          /* Hide scrollbar thumb on hover */
          *::-webkit-scrollbar-thumb:hover {
            background-color: #000; /* Change the color on hover */
          }
        `}
      </style>
      <div className="Theory_Brief">
        <motion.div
          variants={{
            visible: { y: 0 },
            hidden: { y: "-100%" },
          }}
          animate={hidden ? "hidden" : "visible"}
          transition={{ duration: 0.35, ease: "easeInOut" }}
          style={{ position: "sticky", zIndex: "300" }}
        >
          <NavBar />
        </motion.div>

        <div
          className="Main"
          id="Main"
          style={{ position: "fixed", top: "0", left: "0" }}
        >
          <div ref={scope} className="Triangle_main_mobile">
            <motion.div className="FixImages">
              <TheoryTriangle className="workplz" />
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Theory_Brief;
