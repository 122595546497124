import { React, useEffect, useState } from "react";
import Logo_W from "../data/logo/Logo_PD_W.svg";
import Logo_B from "../data/logo/Logo_PD_B.svg";
import { HashLink } from "react-router-hash-link";
import { Link, useLocation, useNavigate } from "react-router-dom";

import EU_Logo_Mobile from "../data/logo/EULogoWhite.png";
import MenuOpen from "../data/Icons/Menu.png";
import MenuOpenBlack from "../data/Icons/Menu_B.png";
import MenuClose from "../data/Icons/MenuClose.png";
import FooterPortugal2020Mobile from "../data/logo/Logo_Portugal_2020_White.png";
import Norte2020Mobile from "../data/logo/Norte_2020_White.png";

import {
  motion,
  useScroll,
  useMotionValueEvent,
  AnimatePresence,
} from "framer-motion";

import "../css/NavBar.css";

import { useTranslation } from "react-i18next";

const NavBar = (props) => {
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const toggleLanguage = () => {
    const currentLanguage = i18n.language;
    const newLanguage = currentLanguage === "en" ? "pt" : "en";

    i18n.changeLanguage(newLanguage);
  };

  const { scrollY } = useScroll();

  const [hidden, setHidden] = useState(false);

  const [logoBlack, setLogoBlack] = useState(false);

  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const handleMouseEnter = (item) => {
    setActiveMenuItem(item);
  };

  const handleMouseLeave = () => {
    setActiveMenuItem(null);
  };

  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious();
    if (latest > previous && latest > 150) {
      setHidden(true);
    } else {
      setHidden(false);
    }

    if (latest < previous && latest > 850 && props.bg == "white") {
      setLogoBlack(true);
      const navBarAnimation = document.getElementById("NavBar__Animation");
      navBarAnimation.style.backgroundColor = "white";
      navBarAnimation.style.transition = "background-color 0.5s ease";
      document.getElementById("NavbarMain").style.color = "black";
      document.getElementById("NavbarMainLanguage").style.color = "black";
      document.getElementById("menuOpen").src = MenuOpenBlack;
    } else if (latest < previous && latest > 850 && props.bg == "black") {
      setLogoBlack(false);
      const navBarAnimation = document.getElementById("NavBar__Animation");
      navBarAnimation.classList.add("NavBar_Black");
      navBarAnimation.style.transition = "background-color 0.5s ease";
      document.getElementById("NavbarMain").style.color = "white";
      document.getElementById("NavbarMainLanguage").style.color = "white";
      document.getElementById("menuOpen").src = MenuOpen;
    } else {
      setLogoBlack(false);
      const navBarAnimation = document.getElementById("NavBar__Animation");
      navBarAnimation.style.backgroundColor = "transparent";
      navBarAnimation.style.transition = "background-color 0.5s ease";
      document.getElementById("NavbarMain").style.color = "white";
      document.getElementById("NavbarMainLanguage").style.color = "white";
      document.getElementById("menuOpen").src = MenuOpen;
    }
  });

  const [open, setOpen] = useState(false);
  const toggleMenu = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (open === true) {
      document.body.classList.add("stop-scrolling");
    } else {
      document.body.classList.remove("stop-scrolling");
    }
  }, [open]);

  const menuVars = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  };

  const currentPage = location.pathname;

  console.log(currentPage, "nav");

  const logoSrc = logoBlack ? Logo_B : Logo_W;

  return (
    <motion.nav
      variants={{
        visible: { y: 0 },
        hidden: { y: "-100%" },
      }}
      animate={hidden ? "hidden" : "visible"}
      transition={{ duration: 0.5, ease: "easeInOut" }}
      className="NavBar__Animation"
      id="NavBar__Animation"
    >
      <div id="navBar__New" className="navbar-container__test__Logo">
        <motion.button
          initial={{ opacity: 0.4 }}
          whileHover={{ opacity: 0.95 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          className="navbar__Grid__item__Language"
          id="NavbarMainLanguage"
          onClick={toggleLanguage}
        >
          <p style={{ fontWeight: "normal" }}>{t("navbar.6")}</p>
        </motion.button>
        <div className="navbar__Grid__Logo">
          <Link to="/">
            <img src={logoSrc} alt="Logo" className="HomeLogo" />
          </Link>
        </div>

        <div id="NavbarMain" className="navbar__Grid">
          {currentPage === "/" ? (
            <a href="/#Work">
              <motion.div
                onMouseEnter={() => handleMouseEnter("work")}
                onMouseLeave={handleMouseLeave}
                initial={{ opacity: 0.4 }}
                whileHover={{ opacity: 0.95 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className={`navbar__Grid__item ${
                  activeMenuItem === "work" ? "active" : ""
                }`}
              >
                {t("navbar.1")}
              </motion.div>
            </a>
          ) : (
            <Link to="/" state={"toWorkFromOut"}>
              <motion.div
                onMouseEnter={() => handleMouseEnter("work")}
                onMouseLeave={handleMouseLeave}
                initial={{ opacity: 0.4 }}
                whileHover={{ opacity: 0.95 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className={`navbar__Grid__item ${
                  activeMenuItem === "work" ? "active" : ""
                }`}
              >
                {t("navbar.1")}
              </motion.div>
            </Link>
          )}
          <Link to="/theory">
            <motion.div
              onMouseEnter={() => handleMouseEnter("theory")}
              onMouseLeave={handleMouseLeave}
              initial={{ opacity: 0.4 }}
              whileHover={{ opacity: 0.95 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              className={`navbar__Grid__item ${
                (activeMenuItem === "theory" &&
                  !(currentPage === "/#Work" || currentPage === "/theory")) ||
                (currentPage !== "/#Work" &&
                  (currentPage === "/theory" ||
                    currentPage === "/TheoryBrief" ||
                    currentPage === "/TheoryAforismos2"))
                  ? "active"
                  : ""
              }`}
            >
              {t("navbar.2")}
            </motion.div>
          </Link>
          <Link to="/about">
            <motion.div
              onMouseEnter={() => handleMouseEnter("about")}
              onMouseLeave={handleMouseLeave}
              initial={{ opacity: 0.4 }}
              whileHover={{ opacity: 0.95 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              className={`navbar__Grid__item ${
                (activeMenuItem === "about" && currentPage !== "/about") ||
                currentPage === "/about"
                  ? "active"
                  : ""
              }`}
            >
              {t("navbar.3")}
            </motion.div>
          </Link>
          <Link to="/awards">
            <motion.div
              onMouseEnter={() => handleMouseEnter("awards")}
              onMouseLeave={handleMouseLeave}
              initial={{ opacity: 0.4 }}
              whileHover={{ opacity: 0.95 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              className={`navbar__Grid__item ${
                (activeMenuItem === "awards" && currentPage !== "/awards") ||
                currentPage === "/awards"
                  ? "active"
                  : ""
              }`}
            >
              {t("navbar.4")}
            </motion.div>
          </Link>
          <Link to="/contacts">
            <motion.div
              onMouseEnter={() => handleMouseEnter("contacts")}
              onMouseLeave={handleMouseLeave}
              initial={{ opacity: 0.4 }}
              whileHover={{ opacity: 0.95 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              className={`navbar__Grid__item ${
                (activeMenuItem === "contacts" &&
                  currentPage !== "/contacts") ||
                currentPage === "/contacts"
                  ? "active"
                  : ""
              }`}
            >
              {t("navbar.5")}
            </motion.div>
          </Link>
        </div>

        <div className="navbar__Grid__Mobile">
          <button className="hamburger__Mobile">
            <img src={MenuOpen} id="menuOpen" onClick={toggleMenu} />
          </button>
        </div>
      </div>
      <AnimatePresence>
        {open && (
          <motion.div
            variants={menuVars}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.25, ease: "easeInOut" }}
            className="menu__Mobile__Grid origin-top"
          >
            <div className="navbar-container__mobile">
              <div className="navbar__Grid__Logo">
                <Link to="/">
                  <img src={Logo_W} alt="Logo" className="HomeLogo" />
                </Link>
              </div>
              <button className="hamburger__Mobile">
                <img src={MenuClose} id="menuClose" onClick={toggleMenu} />
              </button>
            </div>

            <div id="menu" className="menu__Mobile">
              <div className="menu__mobile__left">
                <div>
                  <button
                    className="navbar__Grid__item__Language__mobile"
                    onClick={toggleLanguage}
                  >
                    <p style={{ fontWeight: "normal" }}>{t("navbar.6")}</p>
                  </button>
                </div>
                <div style={{ alignSelf: "end" }}>
                  <div className="menu__mobile__footer_links">
                    <p>© 1985 – 2023</p>
                    <p>ProvidênciaDesign</p>
                  </div>
                  <div className="menu__mobile__Mainfooter__logos">
                    <img alt="Logo" src={Norte2020Mobile} />
                  </div>
                </div>
              </div>
              <div className="menu__mobile__right">
                <div className="menu__mobile__nav">
                  <HashLink
                    smooth
                    to="/#Work"
                    scroll={(el) =>
                      el.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "nearest",
                      })
                    }
                  >
                    <h1>{t("navbar.1")}</h1>
                  </HashLink>
                  <Link to="/Theory">
                    <h1>{t("navbar.2")}</h1>
                  </Link>
                  <Link to="/About">
                    <h1>{t("navbar.3")}</h1>
                  </Link>
                  <Link to="/awards">
                    <h1>{t("navbar.4")}</h1>
                  </Link>
                  <Link to="/contacts">
                    <h1>{t("navbar.5")}</h1>
                  </Link>
                </div>
                <div style={{ alignSelf: "end" }}>
                  <div className="menu__mobile__footer_links">
                    <p>Behance</p>
                    <p>Vimeo</p>
                    <p>LinkedIn</p>
                    <p>Instagram</p>
                    <p>Facebook</p>
                  </div>
                  <div className="menu__mobile__Mainfooter__logos">
                    <img alt="Logo" src={FooterPortugal2020Mobile} />
                    <img alt="Logo" src={EU_Logo_Mobile} />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.nav>
  );
};

export default NavBar;
