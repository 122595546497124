import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Logo_About_R_EN from "../data/logo/About_R_EN.png";
import Logo_About_R_PT from "../data/logo/About_R_PT.png";
import Logo_About_L_EN from "../data/logo/About_L_EN.png";
import Logo_About_L_PT from "../data/logo/About_L_PT.png";
import Down_Arrow from "../data/Icons/Down_Arrow.svg";
import axios from "axios";
import { useMediaQuery } from "react-responsive";

import {
  motion,
  useScroll,
  useMotionValueEvent,
  AnimatePresence,
  useAnimation,
} from "framer-motion";

import "../css/About.css";

import NavBar from "./NavBar";

import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [dataFetch, setDataFetch] = useState();

  const { scrollY } = useScroll();

  const [hidden, setHidden] = useState(false);

  const isMobileS = useMediaQuery({ query: "(max-width: 320px)" });
  const isMobileM = useMediaQuery({
    minWidth: 321,
    maxWidth: 375,
  });
  const isMobileL = useMediaQuery({
    minWidth: 376,
    maxWidth: 425,
  });
  const isTablet = useMediaQuery({
    minWidth: 426,
    maxWidth: 768,
  });
  const isLaptop = useMediaQuery({
    minWidth: 769,
    maxWidth: 1024,
  });
  const isLaptopL = useMediaQuery({
    minWidth: 1025,
    maxWidth: 1440,
  });
  const isDesktop = useMediaQuery({
    minWidth: 1441,
    maxWidth: 1920,
  });

  const isWide = useMediaQuery({
    minWidth: 1921,
  });

  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious();
    if (latest > previous && latest > 1000) {
      setHidden(true);
    } else if (latest < previous && latest < 1400) {
      setHidden(false);
    }
  });

  useEffect(() => {
    const request = async () => {
      await axios.get("https://api.fprovidencia.com/team").then((response) => {
        setDataFetch(response.data);
      });
    };
    request();
  }, []);

  const navigate = useNavigate();
  const handleClick = () => navigate("/TeamInfo");

  const ref = useRef(null);

  const AutoScroll = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const test = (id) => {
    let info = document.getElementsByClassName("profile__content");
    info[0].style.display = "none";

    let featured = document.getElementsByClassName("featured");
    featured[0]?.classList.remove("featured");
    let element = document.getElementById("avatar" + id);
    element.classList.toggle("featured");
    let infoEl = document.getElementById("info" + id);
    infoEl.style.display = "block";
  };

  const [reachedBottom, setReachedBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offsetHeight = document.documentElement.offsetHeight;
      const innerHeight = window.innerHeight;
      const scrollTop = document.documentElement.scrollTop;

      const hasReachedBottom = offsetHeight - (innerHeight + scrollTop) <= 10;

      setReachedBottom(hasReachedBottom);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="bg-black">
        <NavBar />
        <AnimatePresence>
          <motion.div
            animate={hidden ? "hidden" : "visible"}
            variants={{
              visible: { y: 0 },
              hidden: { y: "-100%" },
            }}
            transition={{ duration: 0.35, ease: "easeInOut" }}
            initial="initial"
            exit="exit"
            id="Gradient"
            className="gradient__section"
          ></motion.div>
        </AnimatePresence>

        <main>
          {isLaptopL || isDesktop || isWide === true ? (
            <>
              <div className="about__Grid__test__left">
                <div className="about__Grid__left">
                  <img
                    src={
                      currentLanguage === "pt"
                        ? Logo_About_R_PT
                        : Logo_About_R_EN
                    }
                    id="About__Desktop"
                    alt="Avatar"
                    style={{ maxWidth: "100%" }}
                  ></img>
                  <img
                    src={
                      currentLanguage === "pt"
                        ? Logo_About_L_PT
                        : Logo_About_L_EN
                    }
                    id="About__Mobile"
                    alt="Avatar"
                    style={{ maxWidth: "100%" }}
                  ></img>
                </div>
              </div>
              <div className="about__Grid__test__right">
                <div className="about__Grid__right">
                  <p>{t("about.1")}</p>
                  <p>{t("about.2")}</p>
                  <p>{t("about.3")}</p>
                  <p>{t("about.4")}</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="about__Grid">
                <div className="about__Grid__left">
                  <img
                    src={
                      currentLanguage === "pt"
                        ? Logo_About_R_PT
                        : Logo_About_R_EN
                    }
                    id="About__Desktop"
                    alt="Avatar"
                    style={{ maxWidth: "100%" }}
                  ></img>
                  <img
                    src={
                      currentLanguage === "pt"
                        ? Logo_About_L_PT
                        : Logo_About_L_EN
                    }
                    id="About__Mobile"
                    alt="Avatar"
                    style={{ maxWidth: "100%" }}
                  ></img>
                </div>
                <div className="about__Grid__right">
                  <p>{t("about.1")}</p>
                  <p>{t("about.2")}</p>
                  <p>{t("about.3")}</p>
                  <p>{t("about.4")}</p>
                </div>
              </div>
            </>
          )}
          <AnimatePresence>
            <div className="about__button__grid">
              <motion.div
                initial={{ opacity: 0 }}
                whileHover={{ opacity: 1 }}
                animate={reachedBottom ? "visible" : "hidden"}
                variants={{
                  visible: { opacity: 0.5, display: "block" },
                  hidden: { opacity: 0, display: "none" },
                }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                exit={{ opacity: 0 }}
                className="about__navigation__button"
                id="about__navigation__button"
              >
                <Link to="/Team">
                  <p> {t("loadTeam")}</p>
                  <img style={{opacity: "50%"}} src={Down_Arrow} alt="Down_Arrow" />
                </Link>
              </motion.div>
            </div>
          </AnimatePresence>
        </main>
      </div>
    </>
  );
}

export default About;
