import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import loadingLogo from "../data/logo/Logo_PD_B.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProjectItemMobile = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isLink, setIsLink] = useState(false);

  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  let navigate = useNavigate();

  const handleClick = () => {
    if (isLink === false) {
      setIsHovered(!isHovered);
    } else if (isLink === true) {
      setIsHovered(false); // Set isHovered to false when item is clicked
      navigate(`/ProjectInfo/${props.data.url}`, { state: props.data });
    }
  };

  // useEffect(() => {
  //   let elems = document.getElementsByClassName("card__img");
  //   if (isImageLoaded === false) {
  //     for (var i = 0; i < elems.length; i++) {
  //       elems[i].classList.add("loading");
  //     }
  //   } else {
  //     for (var i = 0; i < elems.length; i++) {
  //       elems[i].classList.remove("loading");
  //     }
  //   }
  // }, [isImageLoaded]);

  useEffect(() => {
    const handleGridItemClick = (event) => {
      const clickedItem = event.target.closest(".main__Grid__item");

      // Check if the clicked element is within an item
      if (clickedItem) {
        // Hide the project title by setting isHovered to false
        setIsHovered(false);
      }
    };

    // Add click event listener to the grid container
    const gridContainer = document.querySelector(".main__Grid");
    gridContainer.addEventListener("click", handleGridItemClick);

    return () => {
      // Remove the event listener on component unmount
      gridContainer.removeEventListener("click", handleGridItemClick);
    };
  }, [setIsHovered]);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      // Check if the clicked element is not within the current item
      if (!event.target.closest(".main__Grid__item")) {
        setIsHovered(false);
      }
    };

    document.body.addEventListener("click", handleDocumentClick);

    return () => {
      document.body.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  useEffect(() => {
    if (isHovered === true) {
      setIsLink(true);
    } else {
      setIsLink(false);
    }
  }, [isHovered]);

  return (
    <motion.div
      className="main__Grid__item"
      onClick={handleClick}
      whileHover={{ opacity: 1 }}
    >
      {/* <Link to={`/ProjectInfo/${props.data.url}`} state={props.data}> */}
      <motion.img
        src={`https://providencia-design-bucket.s3.eu-west-2.amazonaws.com/${props.data.capa}`}
        className={`card__img blurTeste ${isImageLoaded ? "loaded" : ""}`}
        animate={{
          opacity: isImageLoaded ? 1 : 0,
          filter: isImageLoaded ? "blur(0px)" : "blur(8px)",
        }}
        transition={{ duration: 0.5 }}
        onLoad={handleImageLoad}
      />
      <motion.div
        className="project-title"
        initial={{ opacity: 0 }}
        animate={{
          opacity: isHovered ? 1 : 0,
        }}
        transition={{ duration: 0.3 }}
      >
        {currentLanguage === "en" ? (
          <p>{props.data.title.en}</p>
        ) : (
          <p>{props.data.title.pt}</p>
        )}
      </motion.div>
      {/* </Link> */}
    </motion.div>
  );
};

export default ProjectItemMobile;
