import React from "react";
import "../css/Admin.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Sidenav() {
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const currentLanguage = i18n.language;
    const newLanguage = currentLanguage === "en" ? "pt" : "en";

    i18n.changeLanguage(newLanguage);
  };

  return (
    <div className="sidenav">
      <h1>Admin Dashboard</h1>

      <ul>
        <li>
          <a href="#Projects">Projetos</a>
        </li>
        <li>
          <a href="#Team">Equipa</a>
        </li>
        <li>
          <a href="#Awards">
            Prémios, Destinções e Menções Nacionais e Internacionais
          </a>
        </li>
        <li>
          <a href="#Awards2">Prémios em Concursos Nacionais e Internacionais</a>
        </li>
      </ul>

      <Link to="/">
        <h2>Back to Website</h2>
      </Link>

      <button
        style={{ textAlign: "left", paddingTop: "3em" }}
        onClick={toggleLanguage}
      >
        <p>{t("navbar.6")}</p>
      </button>
    </div>
  );
}

export default Sidenav;
