import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminTeamItem from "./AdminTeamItem";
import { Link } from "react-router-dom";

function AdminTeam(props) {
  const [teamData, setTeamData] = useState();

  useEffect(() => {
    const fetchProjects = async () => {
      await axios
        .get("https://api.fprovidencia.com/team")
        .then(function (response) {
          // handle success
          setTeamData(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    };

    fetchProjects();
  }, []);

  return (
    <div id="Team" className="Admin__Main__Grid__Child">
     <div className="Admin__Section">
        <h1 className="Admin__Section__Title">EQUIPA</h1>

        <Link className="Admin__Section__New" to="/Admin/Dashboard/NewTeamMember">
          <button>NOVO MEMBRO</button>
        </Link>
      </div>
      <div className="Admin__Projects__Grid">
        {teamData?.map((data, index) => (
          <AdminTeamItem data={data} index={index} />
        ))}
      </div>
    </div>
  );
}

export default AdminTeam;
