import React from "react";
import { useTranslation } from "react-i18next";

function AwardsItem(props) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div className="Awards__Item">
      <div className="test">
        {props.showYear === true ? <h1>{props.data.year}</h1> : <span></span>}
        {currentLanguage === "pt" ? (
          <h3>{props.data.title.pt}</h3>
        ) : (
          <h3>{props.data.title.en}</h3>
        )}
      </div>
      <div>
        {currentLanguage === "pt" ? (
          <p>{props.data.type.pt}</p>
        ) : (
          <p>{props.data.type.en}</p>
        )}
        {props.type === 1 ? (
          currentLanguage === "pt" ? (
            <p>{props.data?.assignor.pt}</p>
          ) : (
            <p>{props.data?.assignor.en}</p>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default AwardsItem;
