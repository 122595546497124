import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function AdminAwardsItem(props) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const deleteOne = () => {
    if (window.confirm("Delete Award?")) {
      if (props.type === 1) {
        axios.post(
          `https://api.fprovidencia.com/Award_1/deleteOne/${props.data._id}`
        );
      } else {
        axios.post(
          `https://api.fprovidencia.com/Award_2/deleteOne/${props.data._id}`
        );
      }

      console.log("Yes");
    } else {
      console.log("No");
    }
  };

  return (
    <div key={props.index} className="Admin__Award__Item">
      <div className="Admin__Award__Item__Thumbnail">
        {currentLanguage === "pt" ? (
          <p>{props.data.title.pt}</p>
        ) : (
          <p>{props.data.title.en}</p>
        )}
        <p>{props.data.year}</p>
        {props.type === 1 ? (
          currentLanguage === "pt" ? (
            <p>{props.data?.assignor.pt}</p>
          ) : (
            <p>{props.data?.assignor.en}</p>
          )
        ) : (
          <></>
        )}
        <div className="Admin__Award__Item__Icons">
          <button className="Admin__Project__Item__Action" onClick={deleteOne}>
            <AiOutlineDelete />
          </button>
          {props.type === 1 ? (
            <Link
              className="Admin__Project__Item__Action"
              to={`/Admin/Dashboard/EditAward1/${props.data._id}`}
              style={{ cursor: "pointer" }}
            >
              <AiOutlineEdit />
            </Link>
          ) : props.type === 2 ? (
            <Link
              className="Admin__Project__Item__Action"
              to={`/Admin/Dashboard/EditAward2/${props.data._id}`}
              style={{ cursor: "pointer" }}
            >
              <AiOutlineEdit />
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default AdminAwardsItem;
