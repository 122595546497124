import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../css/Admin.css";
import NavBar_Admin from "./NavBar_Admin";

function NewAward1(props) {
  const { id } = useParams();
  const [data, setData] = useState({
    title: { pt: "", en: "" },
    type: { pt: "", en: "" },
    year: "",
    assignor: { pt: "", en: "" },
  });

  useEffect(() => {
    // Fetch team member data using the ID when the component mounts
    const fetchAwardData = async () => {
      try {
        const response = await axios.get(
          `https://api.fprovidencia.com/Award_1/getOne/${id}`
        );
        const awardData = response.data; // Assuming your API returns the team member data

        setData({
          title: { en: awardData.title.en, pt: awardData.title.pt },
          type: { en: awardData.type.en, pt: awardData.type.pt },
          year: awardData.year,
          assignor: { en: awardData.assignor.en, pt: awardData.assignor.pt },
        });
      } catch (error) {
        console.error("Error fetching award data:", error);
      }
    };

    fetchAwardData();
  }, [id]);

  const onDataChange = (fieldname, fieldvalue) => {
    setData((previous) => ({ ...previous, [fieldname]: fieldvalue }));
  };

  const submit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("title[pt]", data.title.pt);
    formData.append("title[en]", data.title.en);
    formData.append("type[pt]", data.type.pt);
    formData.append("type[en]", data.type.en);
    formData.append("year", data.year);
    formData.append("assignor[pt]", data.assignor.pt);
    formData.append("assignor[en]", data.assignor.en);

    try {
      await axios.put(
        `https://api.fprovidencia.com/Award_1/editAward/${id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      alert("Award updated");
      // Redirect or handle success as needed
      window.location.href = "/admin/dashboard";
    } catch (error) {
      console.error("Error updating award:", error);
    }
  };

  return (
    <div className="Admin">
      <NavBar_Admin></NavBar_Admin>
      <div className="Admin__Main__Grid">
        <div className="Admin__Projects__New">
          <div className="Admin__Section">
            <h1 className="Admin__Section__Title">PRÉMIO {data.title.pt}</h1>

            <div className="Admin__Section__New">
              <button type="submit" form="form">
                EDITAR PRÉMIO
              </button>
            </div>
          </div>

          <div>
            <form id="form" onSubmit={submit} className="Admin__New__Form">
              <div id="Title_PT" className="New_Form_Input">
                <p>Title PT</p>
                <input
                  value={data.title.pt}
                  name="title"
                  type="text"
                  className="New_Admin_Input"
                  onChange={(e) =>
                    onDataChange("title", { ...data.title, pt: e.target.value })
                  }
                />
              </div>
              <div id="Title_EN" className="New_Form_Input">
                <p>Title EN</p>
                <input
                  value={data.title.en}
                  name="title"
                  type="text"
                  className="New_Admin_Input"
                  onChange={(e) =>
                    onDataChange("title", { ...data.title, en: e.target.value })
                  }
                />
              </div>
              <div id="Type_PT" className="New_Form_Input">
                <p>Type PT</p>
                <input
                  value={data.type.pt}
                  name="type"
                  type="text"
                  className="New_Admin_Input"
                  onChange={(e) =>
                    onDataChange("type", { ...data.type, pt: e.target.value })
                  }
                />
              </div>
              <div id="Type_EN" className="New_Form_Input">
                <p>Type EN</p>
                <input
                  value={data.type.en}
                  name="type"
                  type="text"
                  className="New_Admin_Input"
                  onChange={(e) =>
                    onDataChange("type", { ...data.type, en: e.target.value })
                  }
                />
              </div>
              <div id="Year_Awards" className="New_Form_Input">
                <p>Year:</p>
                <input
                  name="year"
                  value={data.year}
                  type="number"
                  className="New_Admin_Input"
                  onChange={(e) => onDataChange("year", e.target.value)}
                />
              </div>
              <div id="Assignor_PT" className="New_Form_Input">
                <p>Assignor PT</p>
                <input
                  value={data.assignor.pt}
                  name="assignor"
                  type="text"
                  className="New_Admin_Input"
                  onChange={(e) =>
                    onDataChange("assignor", {
                      ...data.assignor,
                      pt: e.target.value,
                    })
                  }
                />
              </div>
              <div id="Assignor_EN" className="New_Form_Input">
                <p>Assignor EN</p>
                <input
                  value={data.assignor.en}
                  name="assignor"
                  type="text"
                  className="New_Admin_Input"
                  onChange={(e) =>
                    onDataChange("assignor", {
                      ...data.assignor,
                      en: e.target.value,
                    })
                  }
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewAward1;
