import React, { useEffect, useState } from "react";
import Down_Arrow from "../data/Icons/Down_Arrow_black.svg";
import Left_Arrow from "../data/Icons/Left_Arrow.png";
import Right_Arrow from "../data/Icons/Right_Arrow.png";
import { motion } from "framer-motion";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

import "../css/Home.css";

import ProjectItem from "./ProjectItem";
import ProjectItemMobile from "./ProjectItemMobile";
import NavBar from "./NavBar";

import { useMediaQuery } from "react-responsive";

import { useTranslation } from "react-i18next";

function Home() {
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const location = useLocation();

  const state = location.state;

  const currentLanguage = i18n.language;

  let navigate = useNavigate();

  const routeChange = (path, item) => {
    navigate(path, { state: item });
  };

  const isMobileS = useMediaQuery({ query: "(max-width: 320px)" });
  const isMobileM = useMediaQuery({
    minWidth: 321,
    maxWidth: 375,
  });
  const isMobileL = useMediaQuery({
    minWidth: 376,
    maxWidth: 425,
  });
  const isTablet = useMediaQuery({
    minWidth: 426,
    maxWidth: 768,
  });
  const isLaptop = useMediaQuery({
    minWidth: 769,
    maxWidth: 1024,
  });
  const isLaptopL = useMediaQuery({
    minWidth: 1025,
    maxWidth: 1440,
  });
  const isDesktop = useMediaQuery({
    minWidth: 1441,
    maxWidth: 1920,
  });

  const isWide = useMediaQuery({
    minWidth: 1921,
  });

  const [dataFetch, setDataFetch] = useState();
  const [featuredData, setFeaturedData] = useState();

  const [reachedBottom, setReachedBottom] = useState(false);

  const [activeFilter, setActiveFilter] = useState(null);

  const [getAll, setGetAll] = useState(false);

  const [AwardsCurrentIndex, setAwardsCurrentIndex] = useState(0);

  const handleClickDesktop = (event) => {
    // Reset styles for all grid items
    resetGridStyles();

    const item = event.currentTarget;
    const index = Array.from(item.parentNode.children).indexOf(item);
    const rowIndex = Math.floor(index / 4) + 1; // Calculate the row index
    const colIndex = (index % 4) + 1; // Calculate the column index

    // Add your hover effect here
    if (colIndex === 1) {
      item.style.gridColumn = "1 / 3";
    } else if (colIndex === 2) {
      item.style.gridColumn = "2 / 4";
    } else if (colIndex === 3) {
      item.style.gridColumn = "2 / 4";
    } else if (colIndex === 4) {
      item.style.gridColumn = "3 / 5";
    }

    // Set grid-rows dynamically based on the row index
    item.style.gridRow = `${rowIndex} / ${rowIndex + 2}`;
  };

  const handleMouseOut = (event) => {
    const item = event.currentTarget;
    if (item.style) {
      item.style.gridColumn = "";
      item.style.gridRow = ""; // Reset the grid-row style
    }
  };

  const resetGridStyles = () => {
    // Get all items in the grid and reset their styles
    const gridItems = document.querySelectorAll(".grid-item");
    gridItems.forEach((item) => {
      if (item.style) {
        item.style.gridColumn = "";
        item.style.gridRow = "";
      }
    });
  };

  const handleClick = (event) => {
    const item = event.currentTarget;

    // Reset styles for all grid items
    resetGridStyles();

    const index = Array.from(item.parentNode.children).indexOf(item);
    const rowIndex = Math.floor(index / 2) + 1; // Calculate the row index
    const colIndex = (index % 2) + 1; // Calculate the column index

    // Add your hover effect here
    if (colIndex === 1) {
      item.style.gridColumn = "1 / 3";
    } else if (colIndex === 2) {
      item.style.gridColumn = "1 / 3";
    }

    // Set grid-rows dynamically based on the row index
    item.style.gridRow = `${rowIndex} / ${rowIndex + 2}`;
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      // Check if the clicked element is not within the current item
      if (!event.target.closest(".main__Grid__item")) {
        // Reset styles for all grid items
        resetGridStyles();
      }
    };

    document.body.addEventListener("click", handleDocumentClick);

    return () => {
      document.body.removeEventListener("click", handleDocumentClick);
    };
  }, [resetGridStyles]);

  useEffect(() => {
    const handleScroll = () => {
      const offsetHeight = document.documentElement.offsetHeight;
      const innerHeight = window.innerHeight;
      const scrollTop = document.documentElement.scrollTop;

      const hasReachedBottom = offsetHeight - (innerHeight + scrollTop) <= 10;

      setReachedBottom(hasReachedBottom);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const getMoreProjects = () => {
    const request = async () => {
      try {
        const response = await axios.get(
          `https://api.fprovidencia.com/project/recent32`
        );
        setDataFetch(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    request();
    setGetAll(true);
  };

  const getAllProjects = () => {
    const request = async () => {
      try {
        const response = await axios.get(
          `https://api.fprovidencia.com/project/`
        );
        setDataFetch(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    request();
  };

  useEffect(() => {
    const request = async () => {
      try {
        const response = await axios.get(
          "https://api.fprovidencia.com/project/recent16"
        );
        setDataFetch(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    request();
    const requestFeatured = async () => {
      await axios
        .get("https://api.fprovidencia.com/Project/getFeatured")
        .then((response) => {
          setFeaturedData(response.data);
        });
    };
    requestFeatured();
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    const isHomePage = currentPath === "/";

    if (isHomePage && state === "toWorkFromOut") {
      const workSection = document.getElementById("Work");
      if (workSection) {
        window.scrollTo({
          top: workSection.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [dataFetch]);

  const filteredRequest = async (selectedType) => {
    try {
      let url = "https://api.fprovidencia.com/project/getFiltered";

      // Append the selectedType to the URL if it's provided
      if (selectedType) {
        url += `?type=${encodeURIComponent(selectedType)}`;
      }

      const response = await axios.get(url);
      setDataFetch(response.data);
      setActiveFilter(selectedType);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to move to the next item in the carousel
  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === featuredData?.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to move to the previous item in the carousel
  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? featuredData?.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(goToNext, 5000); // Auto transition every 3 seconds

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, [currentIndex]);

  function teste_pt(item) {
    {
      setTimeout(() => {
        if (AwardsCurrentIndex === item.info.awards_pt.length - 1) {
          setAwardsCurrentIndex(0);
        } else {
          setAwardsCurrentIndex(AwardsCurrentIndex + 1);
        }
      }, 2000);
    }
  }

  function teste_en(item) {
    {
      setTimeout(() => {
        if (AwardsCurrentIndex === item.info.awards_en.length - 1) {
          setAwardsCurrentIndex(0);
        } else {
          setAwardsCurrentIndex(AwardsCurrentIndex + 1);
        }
      }, 2000);
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 2 } }}
      exit={{ opacity: 0 }}
    >
      <div className="bg-white">
        <NavBar bg="white" />
        <section>
          <div className="carousel-container">
            <div className="carousel">
              <div className="carousel-nav">
                <motion.img
                  initial={{ opacity: 0.4 }}
                  whileHover={{ opacity: 0.95 }}
                  onClick={goToPrev}
                  src={Left_Arrow}
                ></motion.img>
                <motion.img
                  initial={{ opacity: 0.4 }}
                  whileHover={{ opacity: 0.95 }}
                  onClick={goToNext}
                  src={Right_Arrow}
                ></motion.img>
              </div>
              <div
                className="carousel-inner"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
              >
                {featuredData?.map((item, index) =>
                  item.cover.toLowerCase().includes(".mov") ? (
                    <div
                      className="carousel-item active"
                      key={item.id}
                      onClick={() => {
                        routeChange(`/ProjectInfo/${item.url}`, item); // Pass 'item' as an argument
                      }}
                    >
                      <video
                        src={`https://providencia-design-bucket.s3.eu-west-2.amazonaws.com/${item.cover}`}
                        autoPlay
                        loop
                        muted
                        type="mp4"
                      />
                      <div className="Carousel__Info">
                        <div key={item.id} className="info__item">
                          <h1> {t(`types.${item.type}`)}</h1>
                          {currentLanguage === "en" ? (
                            <p>{item.title.en}</p>
                          ) : (
                            <p>{item.title.pt}</p>
                          )}
                        </div>

                        {currentLanguage === "en" ? (
                          <div className="info__item">
                            {JSON.stringify(item.info.awards_en) ===
                            JSON.stringify([""]) ? (
                              <></>
                            ) : (
                              <>
                                <h1>{t("carousel.1")}</h1>
                                {item.info.awards_en.length > 1 ? (
                                  <>
                                    {teste_en(item)}
                                    <p>
                                      {item.info.awards_en[AwardsCurrentIndex]}
                                    </p>
                                  </>
                                ) : (
                                  <p>{item.info.awards_en[0]}</p>
                                )}
                              </>
                            )}
                          </div>
                        ) : (
                          <div className="info__item">
                            {JSON.stringify(item.info.awards_pt) ===
                            JSON.stringify([""]) ? (
                              <></>
                            ) : (
                              <>
                                <h1>{t("carousel.1")}</h1>
                                {item.info.awards_pt.length > 1 ? (
                                  <>
                                    {teste_pt(item)}
                                    <p>
                                      {item.info.awards_pt[AwardsCurrentIndex]}
                                    </p>
                                  </>
                                ) : (
                                  <p>{item.info.awards_pt[0]}</p>
                                )}
                              </>
                            )}
                          </div>
                        )}

                        <div className="info__item">
                          <h1>{index + 1 + " / " + featuredData.length}</h1>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="carousel-item active"
                      key={item.id}
                      onClick={() => {
                        routeChange(`/ProjectInfo/${item.url}`, item); // Pass 'item' as an argument
                      }}
                    >
                      <img
                        src={`https://providencia-design-bucket.s3.eu-west-2.amazonaws.com/${item.cover}`}
                        alt="Projeto Cover"
                      />
                      <div className="Carousel__Info">
                        <div key={item.id} className="info__item">
                          <h1> {t(`types.${item.type}`)}</h1>
                          {currentLanguage === "en" ? (
                            <p>{item.title.en}</p>
                          ) : (
                            <p>{item.title.pt}</p>
                          )}
                        </div>
                        {currentLanguage === "en" ? (
                          <div className="info__item">
                            {JSON.stringify(item.info.awards_en) ===
                            JSON.stringify([""]) ? (
                              <></>
                            ) : (
                              <>
                                <h1>{t("carousel.1")}</h1>
                                {item.info.awards_en.length > 1 ? (
                                  <>
                                    {teste_en(item)}
                                    <p>
                                      {item.info.awards_en[AwardsCurrentIndex]}
                                    </p>
                                  </>
                                ) : (
                                  <p>{item.info.awards_en[0]}</p>
                                )}
                              </>
                            )}
                          </div>
                        ) : (
                          <div className="info__item">
                            {JSON.stringify(item.info.awards_pt) ===
                            JSON.stringify([""]) ? (
                              <></>
                            ) : (
                              <>
                                <h1>{t("carousel.1")}</h1>
                                {item.info.awards_pt.length > 1 ? (
                                  <>
                                    {teste_pt(item)}
                                    <p>
                                      {item.info.awards_pt[AwardsCurrentIndex]}
                                    </p>
                                  </>
                                ) : (
                                  <p>{item.info.awards_pt[0]}</p>
                                )}
                              </>
                            )}
                          </div>
                        )}
                        <div className="info__item">
                          <h1>{index + 1 + " / " + featuredData.length}</h1>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </section>

        <div id="Work" className="grid-container__test">
          <div className="filter__all">
            <motion.div
              initial={{ opacity: 0.4 }}
              whileHover={{ opacity: 0.95 }}
              className={!activeFilter ? "filter__active" : ""}
              onClick={() => filteredRequest()}
            >
              {t("filter.1")}
            </motion.div>
          </div>
          <div className="filter__container">
            <motion.div
              initial={{ opacity: 0.4 }}
              whileHover={{ opacity: 0.95 }}
              className={activeFilter === "Museography" ? "filter__active" : ""}
              onClick={() => filteredRequest("Museography")}
            >
              {t("filter.5")}
            </motion.div>
            <motion.div
              initial={{ opacity: 0.4 }}
              whileHover={{ opacity: 0.95 }}
              className={activeFilter === "Product" ? "filter__active" : ""}
              onClick={() => filteredRequest("Product")}
            >
              {t("filter.6")}
            </motion.div>
            <motion.div
              initial={{ opacity: 0.4 }}
              whileHover={{ opacity: 0.95 }}
              className={activeFilter === "Signage" ? "filter__active" : ""}
              onClick={() => filteredRequest("Signage")}
            >
              {t("filter.7")}
            </motion.div>
            <motion.div
              initial={{ opacity: 0.4 }}
              whileHover={{ opacity: 0.95 }}
              className={activeFilter === "Branding" ? "filter__active" : ""}
              onClick={() => filteredRequest("Branding")}
            >
              {t("filter.2")}
            </motion.div>
            <motion.div
              initial={{ opacity: 0.4 }}
              whileHover={{ opacity: 0.95 }}
              className={activeFilter === "Editorial" ? "filter__active" : ""}
              onClick={() => filteredRequest("Editorial")}
            >
              {t("filter.4")}
            </motion.div>
            <motion.div
              initial={{ opacity: 0.4 }}
              whileHover={{ opacity: 0.95 }}
              className={activeFilter === "Posters" ? "filter__active" : ""}
              onClick={() => filteredRequest("Posters")}
            >
              {t("filter.3")}
            </motion.div>
          </div>
        </div>

        <div className="grid-container__filter__mobile">
          <div className="test__test">
            <motion.h1
              initial={{ opacity: 0.4 }}
              className={!activeFilter ? "filter__active" : ""}
              onClick={() => filteredRequest()}
            >
              {t("filter.1")}
            </motion.h1>
            <motion.h1
              initial={{ opacity: 0.4 }}
              className={activeFilter === "Museography" ? "filter__active" : ""}
              onClick={() => filteredRequest("Museography")}
            >
              {t("filter.5")}
            </motion.h1>
            <motion.h1
              initial={{ opacity: 0.4 }}
              className={activeFilter === "Product" ? "filter__active" : ""}
              onClick={() => filteredRequest("Product")}
            >
              {t("filter.6")}
            </motion.h1>
            <motion.h1
              initial={{ opacity: 0.4 }}
              className={activeFilter === "Signage" ? "filter__active" : ""}
              onClick={() => filteredRequest("Signage")}
            >
              {t("filter.7")}
            </motion.h1>
          </div>
          <div className="test__test">
            <motion.h1
              initial={{ opacity: 0.4 }}
              className={activeFilter === "Branding" ? "filter__active" : ""}
              onClick={() => filteredRequest("Branding")}
            >
              {t("filter.2")}
            </motion.h1>
            <motion.h1
              initial={{ opacity: 0.4 }}
              className={activeFilter === "Editorial" ? "filter__active" : ""}
              onClick={() => filteredRequest("Editorial")}
            >
              {t("filter.4")}
            </motion.h1>
            <motion.h1
              initial={{ opacity: 0.4 }}
              className={activeFilter === "Posters" ? "filter__active" : ""}
              onClick={() => filteredRequest("Posters")}
            >
              {t("filter.3")}
            </motion.h1>
          </div>
        </div>

        {isWide || isDesktop || isLaptopL ? (
          <div className="main__Grid" id="main-grid">
            {dataFetch?.map((data, index) => (
              <div
                className="grid-item"
                key={data.id}
                onClick={handleClickDesktop}
              >
                <ProjectItemMobile index={data._id} data={data} />
              </div>
            ))}
          </div>
        ) : (
          <div className="main__Grid">
            {dataFetch?.map((data, index) => (
              <div className="grid-item" key={data.id} onClick={handleClick}>
                <ProjectItemMobile index={data.id} data={data} />
              </div>
            ))}
          </div>
        )}

        <motion.div
          initial={{ opacity: 0 }}
          whileHover={{ opacity: 0.5 }}
          animate={reachedBottom ? "visible" : "hidden"}
          variants={{
            visible: { opacity: 0.25, display: "block" },
            hidden: { opacity: 0, display: "none" },
          }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          exit={{ opacity: 0 }}
          id="Top__Arrow"
        >
          <a href="#top" className="Top__Arrow__Home">
            <img style={{ transform: "rotate(180deg)" }} src={Down_Arrow} />
            <p>{t("anchor")}</p>
          </a>
        </motion.div>

        {getAll ? (
          <motion.div
            id="getAll"
            initial={{ opacity: 0.25 }}
            whileHover={{ opacity: 0.5 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className="More__Projects__Grid"
            onClick={getAllProjects}
          >
            <p>{t("loadProjects")}</p>
            <img src={Down_Arrow}></img>
          </motion.div>
        ) : (
          <motion.div
            id="get32"
            initial={{ opacity: 0.25 }}
            whileHover={{ opacity: 0.5 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className="More__Projects__Grid"
            onClick={getMoreProjects}
          >
            <p>{t("loadProjects")}</p>
            <img src={Down_Arrow}></img>
          </motion.div>
        )}

        <Footer bg={"white"} />
      </div>
    </motion.div>
  );
}

export default Home;
