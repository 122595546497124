import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";

const Triade = () => {
  const [scrollY, setScrollY] = useState(0);
  const controls = useAnimation();

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    controls.start({
      rotate: scrollY / 5, // Adjust the divisor to control the rotation speed
    });
  }, [scrollY, controls]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <motion.img
      src="/images/Theory/Gráfico_Tripartido/4x/PT/Asset 36@4x.png" // Replace with your image URL
      alt="Rotating Image"
      style={{
        width: "200px", // Adjust the width as needed
        height: "200px", // Adjust the height as needed
      }}
      animate={controls}
    />
  );
};

export default Triade;
