import React, { useEffect } from "react";
import FooterPortugal2020Cores from "../data/logo/Logo_Portugal_2020_Cores.jpg";
import Norte2020Cores from "../data/logo/Norte_2020_Cores.jpg";
import EU_LogoCores from "../data/logo/EULogoCores.png";

import FooterPortugal2020Branco from "../data/logo/Logo_Portugal_2020_White.png";
import Norte2020Branco from "../data/logo/Norte_2020_White.png";
import EU_Logo_Mobile from "../data/logo/EULogoWhite.png";

import { useMediaQuery } from "react-responsive";

function Footer(props) {
  const isMobileS = useMediaQuery({ query: "(max-width: 320px)" });
  const isMobileM = useMediaQuery({
    minWidth: 321,
    maxWidth: 375,
  });
  const isMobileL = useMediaQuery({
    minWidth: 376,
    maxWidth: 425,
  });
  const isTablet = useMediaQuery({
    minWidth: 426,
    maxWidth: 768,
  });
  const isLaptop = useMediaQuery({
    minWidth: 769,
    maxWidth: 1024,
  });

  useEffect(() => {
    if (
      isMobileS === true ||
      isMobileM === true ||
      isMobileL === true ||
      isTablet === true ||
      isLaptop === true
    ) {
      if (props.contacts === "true") {
        document.getElementById("footer").style.position = "sticky";
        document.getElementById("footer").style.bottom = 0;
        document.getElementById("footer").style.left = 0;
      }
    } else {
      if (props.contacts === "true") {
        document.getElementById("footer").style.position = "sticky";
        document.getElementById("footer").style.bottom = 0;
        document.getElementById("footer").style.left = 0;
      } else {
        document.getElementById("footer").style.position = "relative";
      }
    }
  }, [isMobileS, isMobileM, isMobileL, isTablet, isLaptop]);

  const handleOpenBehance = () => {
    const behanceUrl = "https://www.behance.net/providenciadesign";
    window.open(behanceUrl, "_blank");
  };

  const handleOpenLinkedIn = () => {
    const linkedInUrl =
      "https://www.linkedin.com/company/provid%C3%AAnciadesign/";
    window.open(linkedInUrl, "_blank");
  };

  const handleOpenVimeo = () => {
    const VimeoUrl = "https://www.vimeo.com/providenciadesign";
    window.open(VimeoUrl, "_blank");
  };

  const handleOpenInstagram = () => {
    const InstagramUrl = "https://www.instagram.com/providenciadesign/";
    window.open(InstagramUrl, "_blank");
  };

  const handleOpenFacebook = () => {
    const FacebookUrl = "https://www.facebook.com/providenciadesign/";
    window.open(FacebookUrl, "_blank");
  };

  return (
    <div id="footer" style={{ position: "relative", zIndex: "100" }}>
      {props.bg === "white" ? (
        <>
          <div className="grid-container__footer">
            <div className="footer__logos__test">
              <div>
                <img alt="Logo" src={Norte2020Cores} />
              </div>
              <div>
                <img alt="Logo" src={FooterPortugal2020Cores} />
              </div>
              <div>
                <img alt="Logo" src={EU_LogoCores} />
              </div>
            </div>
            <div className="footer__links__test">
              <div>
                <span>
                  © 1985 – 2023<br></br>ProvidênciaDesign
                </span>
              </div>
              <div onClick={handleOpenBehance}>
                <p>Behance</p>
              </div>
              <div onClick={handleOpenVimeo}>
                <p>Vimeo</p>
              </div>
              <div onClick={handleOpenLinkedIn}>
                <p>LinkedIn</p>
              </div>
              <div onClick={handleOpenInstagram}>
                <p>Instagram</p>
              </div>
              <div onClick={handleOpenFacebook}>
                <p>Facebook</p>
              </div>
            </div>
          </div>
          <div className="grid-container__footer__mobile__home">
            <div className="menu__mobile__left">
              <div style={{ alignSelf: "end" }}>
                <div className="menu__mobile__Mainfooter_links">
                  <p style={{ opacity: "60%", fontSize: "12px" }}>
                    © 1985 – 2023
                  </p>
                  <p style={{ opacity: "60%", fontSize: "12px" }}>
                    ProvidênciaDesign
                  </p>
                </div>
                <div className="menu__mobile__Mainfooter__logos">
                  <img alt="Logo" src={Norte2020Cores} />
                </div>
              </div>
            </div>
            <div className="menu__mobile__right">
              <div>
                <div className="menu__mobile__Mainfooter_links">
                  <p style={{ cursor: "pointer" }} onClick={handleOpenBehance}>
                    Behance
                  </p>
                  <p style={{ cursor: "pointer" }} onClick={handleOpenVimeo}>
                    Vimeo
                  </p>
                  <p style={{ cursor: "pointer" }} onClick={handleOpenLinkedIn}>
                    LinkedIn
                  </p>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenInstagram}
                  >
                    Instagram
                  </p>
                  <p style={{ cursor: "pointer" }} onClick={handleOpenFacebook}>
                    Facebook
                  </p>
                </div>
                <div className="menu__mobile__Mainfooter__logos">
                  <img alt="Logo" src={FooterPortugal2020Cores} />
                  <img alt="Logo" src={EU_LogoCores} />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="grid-container__footer">
            <div className="footer__logos__test">
              <div>
                <img alt="Logo" src={Norte2020Branco} />
              </div>
              <div>
                <img alt="Logo" src={FooterPortugal2020Branco} />
              </div>
              <div>
                <img alt="Logo" src={EU_Logo_Mobile} />
              </div>
            </div>
            <div className="footer__links__test" style={{ color: "white" }}>
              {props.contacts === "true" ? (
                <>
                  <div>
                    <span>
                      © 1985 – 2023<br></br>ProvidênciaDesign
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <span>
                      © 1985 – 2023<br></br>ProvidênciaDesign
                    </span>
                  </div>
                  <div onClick={handleOpenBehance}>
                    <p>Behance</p>
                  </div>
                  <div onClick={handleOpenVimeo}>
                    <p>Vimeo</p>
                  </div>
                  <div onClick={handleOpenLinkedIn}>
                    <p>LinkedIn</p>
                  </div>
                  <div onClick={handleOpenInstagram}>
                    <p>Instagram</p>
                  </div>
                  <div onClick={handleOpenFacebook}>
                    <p>Facebook</p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="grid-container__footer__mobile__home">
            <div className="menu__mobile__left">
              <div style={{ alignSelf: "end" }}>
                <div
                  className="menu__mobile__Mainfooter_links"
                  style={{ color: "white" }}
                >
                  <p>© 1985 – 2023</p>
                  <p>ProvidênciaDesign</p>
                </div>
                <div className="menu__mobile__Mainfooter__logos">
                  <img alt="Logo" src={Norte2020Branco} />
                </div>
              </div>
            </div>
            <div className="menu__mobile__right">
              <div>
                <div
                  className="menu__mobile__Mainfooter_links"
                  style={{ color: "white" }}
                >
                  {props.contacts === "true" ? (
                    <></>
                  ) : (
                    <>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={handleOpenBehance}
                      >
                        Behance
                      </p>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={handleOpenVimeo}
                      >
                        Vimeo
                      </p>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={handleOpenLinkedIn}
                      >
                        LinkedIn
                      </p>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={handleOpenInstagram}
                      >
                        Instagram
                      </p>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={handleOpenFacebook}
                      >
                        Facebook
                      </p>
                    </>
                  )}
                </div>
                <div className="menu__mobile__Mainfooter__logos">
                  <img alt="Logo" src={FooterPortugal2020Branco} />
                  <img alt="Logo" src={EU_Logo_Mobile} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Footer;
