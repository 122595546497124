import React, { useEffect } from "react";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";

import "../css/About.css";
import NavBar from "./NavBar";

import { useTranslation } from "react-i18next";

import { useMediaQuery } from "react-responsive";

function TeamInfo() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const location = useLocation();

  const navigate = useNavigate();

  const data = location.state;

  const isMobileS = useMediaQuery({ query: "(max-width: 320px)" });
  const isMobileM = useMediaQuery({
    minWidth: 321,
    maxWidth: 375,
  });
  const isMobileL = useMediaQuery({
    minWidth: 376,
    maxWidth: 425,
  });
  const isTablet = useMediaQuery({
    minWidth: 426,
    maxWidth: 768,
  });
  const isLaptop = useMediaQuery({
    minWidth: 769,
    maxWidth: 1024,
  });

  useEffect(() => {
    const handleDocumentClick = (event) => {
      // Check if the clicked element is not within the current item
      if (
        !event.target.closest(".avatarIcon__Item") &&
        !event.target.closest(".teamInfo__Text__Item")
      ) {
        // Reset styles for all grid items
        navigate("/Team");
      }
    };

    document.body.addEventListener("click", handleDocumentClick);

    return () => {
      document.body.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <>
      <div className="bg-black">
        <NavBar />

        <main className="teamInfo__Grid">
          <div className="avatarIcon__Item">
            <img
              src={`https://providencia-design-bucket.s3.eu-west-2.amazonaws.com/${data.avatar}`}
            />
          </div>

          <div className="teamInfo__Text__Item">
            <h1>{data.name}</h1>
            <h2>{data.position}</h2>
            {currentLanguage === "pt" ? (
              <p dangerouslySetInnerHTML={{ __html: data.bio.pt }}></p>
            ) : (
              <p dangerouslySetInnerHTML={{ __html: data.bio.en }}></p>
            )}
          </div>
        </main>

        {isMobileS === true ||
        isMobileM === true ||
        isMobileL === true ||
        isTablet === true ||
        isLaptop === true ? (
          <Footer bg={"black"} />
        ) : (
          <div
            style={{
              height: "100vh",
              width: "100vw",
              position: "absolute",
              bottom: 0,
              left: 0,
              display: "grid",
              alignItems: "end",
            }}
          >
            <Footer bg={"black"} />
          </div>
        )}
      </div>
    </>
  );
}

export default TeamInfo;
