import React, { useState } from "react";
import axios from "axios";
import ReactQuill from "react-quill"; // Import the ReactQuill component
import "react-quill/dist/quill.snow.css"; // Import the styles for the editor
import "../css/Admin.css";
import NavBar_Admin from "./NavBar_Admin";

function NewTeam(props) {
  const [data, setData] = useState({
    name: "",
    position: "",
    avatar: null,
    bio: { en: "", pt: "" },
  });

  const onDataChange = (fieldname, fieldvalue) => {
    setData((previous) => ({ ...previous, [fieldname]: fieldvalue }));
  };

  const submit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("position", data.position);
    formData.append("bio[en]", data.bio.en);
    formData.append("bio[pt]", data.bio.pt);

    for (let i = 0; i < data.avatar.length; i++) {
      formData.append("avatar", data.avatar[i]);
    }

    await axios.post("https://api.fprovidencia.com/Team/NewTeam", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    alert("New Team Member added");
    window.location.href = "/admin/dashboard";
  };

  return (
    <div className="Admin">
      <NavBar_Admin></NavBar_Admin>
      <div className="Admin__Main__Grid">
        <div className="Admin__Projects__New">
          <div className="Admin__Section">
            <h1 className="Admin__Section__Title">NOVO MEMBRO</h1>

            <div className="Admin__Section__New">
              <button type="submit" form="form">
                CRIAR MEMBRO
              </button>
            </div>
          </div>

          <div>
            <form id="form" onSubmit={submit} className="Admin__New__Form">
              <div id="Nome" className="New_Form_Input">
                <p>Nome</p>
                <input
                  name="name"
                  type="text"
                  className="New_Admin_Input"
                  onChange={(e) => onDataChange("name", e.target.value)}
                />
              </div>
              <div id="Cargo" className="New_Form_Input">
                <p>Cargo</p>
                <input
                  name="position"
                  type="text"
                  className="New_Admin_Input"
                  onChange={(e) => onDataChange("position", e.target.value)}
                />
              </div>
              <div id="Bio_PT" className="New_Form_Input">
                <p>Biografia PT</p>
                <ReactQuill
                  value={data.bio.pt}
                  style={{
                    paddingBottom: "4em",
                    width: "100%",
                    minHeight: "200px",
                  }}
                  className="New_Admin_Input__text"
                  onChange={(value) =>
                    onDataChange("bio", { ...data.bio, pt: value })
                  }
                />
              </div>
              <div id="Bio_EN" className="New_Form_Input">
                <p>Bio EN</p>
                <ReactQuill
                  value={data.bio.en}
                  className="New_Admin_Input__text"
                  onChange={(value) =>
                    onDataChange("bio", { ...data.bio, en: value })
                  }
                />
              </div>
              <div id="Avatar" className="New_Form_Input">
                <p>Imagem</p>
                <input
                  name="avatar"
                  className="New_Admin_Input"
                  onChange={(e) => onDataChange("avatar", e.target.files)}
                  type="file"
                  accept="image/*, video/*"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewTeam;
