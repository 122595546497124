import "./App.css";
import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Work from "./pages/Work";
import Theory from "./pages/Theory";
import Theory_Brief from "./pages/Theory_Brief";
import About from "./pages/About";
import Awards from "./pages/Awards";
import Contacts from "./pages/Contacts";
import TeamInfo from "./pages/TeamInfo";
import ProjectInfo from "./pages/ProjectInfo.js";

import Admin from "./pages/Admin";
import Dashboard from "./pages/Dashboard";
import AdminProjects from "./pages/AdminProjects";
import AdminTeam from "./pages/AdminTeam";
import AdminAwards from "./pages/AdminAwards";
import NewProject from "./pages/NewProject";
import NewTeam from "./pages/NewTeam";
import NewAward from "./pages/NewAward";
import NewAward2 from "./pages/NewAward2";

import SmoothScroll from "./pages/SmoothScroll";
import { AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";
import TheoryAforismos from "./pages/TheoryAforismos";
import TheoryAforismos2 from "./pages/TheoryAforismos2";
import Team from "./pages/Team";
import EditTeam from "./pages/EditTeam";
import EditAward1 from "./pages/EditAward1";
import EditAward2 from "./pages/EditAward2";
import EditProject from "./pages/EditProject";
import PrivateRoute from "./data/PrivateRoute";

function App() {
  const location = useLocation();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Use useEffect to check authentication status (e.g., from a token in local storage)
  useEffect(() => {
    // Implement your logic to check authentication status
    const token = localStorage.getItem("token");
    setIsAuthenticated(!!token); // Set to true if a token exists
  }, []);

  return (
    <SmoothScroll>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />}></Route>
          <Route path="/ProjectInfo/:title" element={<ProjectInfo />}></Route>
          <Route path="/Work" element={<Work />}></Route>
          <Route path="/Theory" element={<Theory />}></Route>
          <Route path="/TheoryBrief" element={<Theory_Brief />}></Route>
          <Route path="TheoryAforismos2" element={<TheoryAforismos />}></Route>
          <Route path="TheoryAforismos" element={<TheoryAforismos2 />}></Route>
          <Route path="/About" element={<About />}></Route>
          <Route path="/Team" element={<Team />}></Route>
          <Route path="/TeamInfo/:name" element={<TeamInfo />}></Route>
          <Route path="/Awards" element={<Awards />}></Route>
          <Route path="/Contacts" element={<Contacts />}></Route>
          <Route path="/Admin" element={<Admin />}></Route>
          {/* <Route path="/Admin/Dashboard" element={<Dashboard />}></Route> */}
          <Route
            path="/admin/*"
            element={
              isAuthenticated ? (
                <Routes>
                  <Route path="dashboard" element={<Dashboard />} />
                </Routes>
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/Admin/Dashboard/AdminProjects"
            element={<AdminProjects />}
          ></Route>
          <Route
            path="/Admin/Dashboard/AdminTeam"
            element={<AdminTeam />}
          ></Route>
          <Route
            path="/Admin/Dashboard/AdminAwards"
            element={<AdminAwards />}
          ></Route>
          <Route
            path="/Admin/Dashboard/NewProject"
            element={<NewProject />}
          ></Route>
          <Route
            path="/Admin/Dashboard/NewTeamMember"
            element={<NewTeam />}
          ></Route>
          <Route
            path="/Admin/Dashboard/EditTeamMember/:id"
            element={<EditTeam />}
          ></Route>
          <Route
            path="/Admin/Dashboard/NewAward"
            element={<NewAward />}
          ></Route>
          <Route
            path="/Admin/Dashboard/NewAward2"
            element={<NewAward2 />}
          ></Route>
          <Route
            path="/Admin/Dashboard/EditAward1/:id"
            element={<EditAward1 />}
          ></Route>
          <Route
            path="/Admin/Dashboard/EditAward2/:id"
            element={<EditAward2 />}
          ></Route>
          <Route
            path="/Admin/Dashboard/EditProject/:id"
            element={<EditProject />}
          ></Route>
        </Routes>
      </AnimatePresence>
    </SmoothScroll>
  );
}

export default App;
