import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Down_Arrow from "../data/Icons/Down_Arrow_black.svg";
import axios from "axios";
import { useLocation } from "react-router-dom";
import NavBar from "./NavBar";
import { motion, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";

function ProjectInfo() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This provides a smooth scrolling effect, but it's optional
    });
  }, []);

  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const location = useLocation();
  const data = location.state;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This provides a smooth scrolling effect, but it's optional
    });
  };

  const [dataFetch, setDataFetch] = useState();

  const [loaded, setLoaded] = useState(false);

  const [reachedBottom, setReachedBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offsetHeight = document.documentElement.offsetHeight;
      const innerHeight = window.innerHeight;
      const scrollTop = document.documentElement.scrollTop;

      const hasReachedBottom = offsetHeight - (innerHeight + scrollTop) <= 10;

      setReachedBottom(hasReachedBottom);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverNextItem = () => {
    setIsHovered(!isHovered);
  };

  const array = Object.values(data.content);

  useEffect(() => {
    const request = async () => {
      try {
        const response = await axios.get(
          `https://api.fprovidencia.com/project/randomItemExcludingId/${location.state._id}`
        );
        setDataFetch(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    request();
  }, []);

  const hoverNextControl = useAnimation();
  const hoverTitleControl = useAnimation();
  const hoverTypeControl = useAnimation();

  const hoverNext = () => {
    hoverNextControl.start({ opacity: 0.6, color: "red" });
    hoverTitleControl.start({ opacity: 0.9 });
    hoverTypeControl.start({ opacity: 0.6 });
  };

  const resetHover = () => {
    hoverNextControl.start({ opacity: 0.6, color: "black" });
    hoverTitleControl.start({ opacity: 0.5 });
    hoverTypeControl.start({ opacity: 0.3 });
  };

  return (
    <>
      <div className="bg-white">
        <NavBar bg="white" />
        <section>
          <div className="carousel-container">
            <div className="carousel">
              <div className="carousel-inner">
                {data.cover.toLowerCase().includes(".mov") ? (
                  <div className="carousel-item active">
                    <video
                      src={`https://providencia-design-bucket.s3.eu-west-2.amazonaws.com/${data.cover}`}
                      autoPlay
                      preload="auto"
                      loop
                      muted
                      type="mp4"
                    />
                  </div>
                ) : (
                  <div className="carousel-item active">
                    <img
                      src={`https://providencia-design-bucket.s3.eu-west-2.amazonaws.com/${data.cover}`}
                      alt={data.title}
                      className="blurTeste"
                      style={loaded ? { opacity: 1 } : { opacity: 0 }}
                      onLoad={() => setLoaded(true)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="Awards__Info__Grid">
            <div className="awardsInfo">
              {data.awards_img.map((awardImg, index) => (
                <div key={index}>
                  <img
                    src={`https://providencia-design-bucket.s3.eu-west-2.amazonaws.com/${awardImg}`}
                    alt={`Award ${index + 1}`}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>

        <main>
          <div className="ProjectInfo__Grid">
            {array.map((e) =>
              e.toLowerCase().includes(".mov") ? (
                <div>
                  <video
                    src={`https://providencia-design-bucket.s3.eu-west-2.amazonaws.com/${e}`}
                    autoPlay
                    muted
                    loop
                    type="mp4"
                  />
                </div>
              ) : (
                <div>
                  <img
                    src={`https://providencia-design-bucket.s3.eu-west-2.amazonaws.com/${e}`}
                  />
                </div>
              )
            )}

            {data.content_text.body === [] ? (
              <></>
            ) : (
              <div className="ProjectInfo__Text">
                {currentLanguage === "en" ? (
                  <>
                    <h1>{data.content_text.body.en}</h1>
                    <p>{data.content_text.title.en}</p>
                  </>
                ) : (
                  <>
                    <h1>{data.content_text.body.pt}</h1>
                    <p>{data.content_text.title.pt}</p>
                  </>
                )}
              </div>
            )}
          </div>
        </main>

        <div className="Tech__Info__Grid">
          <div className="TechInfo__left">
            <div className="title__and__subtitle">
              {currentLanguage === "en" ? (
                <h1>{data.title.en}</h1>
              ) : (
                <h1>{data.title.pt}</h1>
              )}

              <h2>{t(`types.${data.type}`)}</h2>
            </div>
          </div>
          <div className="TechInfo__right">
            <div>
              <h3>{t("ProjectInfo.client")}</h3>
            </div>
            <div>
              {currentLanguage === "en" ? (
                <p>{data.info.client.en}</p>
              ) : (
                <p>{data.info.client.pt}</p>
              )}
            </div>
            <div>
              <h3>{t("ProjectInfo.year")}</h3>
            </div>
            <div>
              <p>{data.info.year}</p>
            </div>
            <div>
              <h3>{t("ProjectInfo.team")}</h3>
            </div>
            <div>
              {currentLanguage === "en" ? (
                <p dangerouslySetInnerHTML={{ __html: data.info.team.en }} />
              ) : (
                <p dangerouslySetInnerHTML={{ __html: data.info.team.pt }} />
              )}
            </div>
            {data.info.awards_pt[0] === "" || data.info.awards_en[0] === "" ? (
              <></>
            ) : (
              <>
                {" "}
                <div>
                  <h3>{t("ProjectInfo.awards")}</h3>
                </div>
                <div style={{ paddingBottom: "0" }}>
                  {currentLanguage === "en" ? (
                    <>
                      {data.info.awards_en.map((award, index) => (
                        <p key={index}>{award}</p>
                      ))}
                    </>
                  ) : (
                    <>
                      {data.info.awards_pt.map((award, index) => (
                        <p key={index}>{award}</p>
                      ))}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          whileHover={{ opacity: 0.5 }}
          animate={reachedBottom ? "visible" : "hidden"}
          variants={{
            visible: { opacity: 0.25 },
            hidden: { opacity: 0 },
          }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          exit={{ opacity: 0 }}
          id="Top__Arrow"
        >
          <div onClick={scrollToTop} className="Top__Arrow__Home">
            <img style={{ transform: "rotate(180deg)" }} src={Down_Arrow} />
            <p>{t("anchor")}</p>
          </div>
        </motion.div>

        <div className="NextProject">
          <motion.div whileHover={hoverNext} onMouseLeave={resetHover}>
            <hr className="line__New" />
          </motion.div>

          <div className="NextProject__Info">
            <motion.div whileHover={hoverNext} onMouseLeave={resetHover}>
              <Link to={`/ProjectInfo/${dataFetch?.url}`} state={dataFetch}>
                <motion.h3
                  initial={{ opacity: 0.6, color: "black" }}
                  animate={hoverNextControl}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                >
                  {t("ProjectInfo.next")}
                </motion.h3>
              </Link>
            </motion.div>
            <motion.div
              whileHover={hoverNext}
              onMouseLeave={resetHover}
              className="NextProject__Title"
            >
              <Link to={`/ProjectInfo/${dataFetch?.url}`} state={dataFetch}>
                <motion.h1
                  initial={{ opacity: 0.5 }}
                  animate={hoverTitleControl}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                >
                  {currentLanguage === "en" ? (
                    <h1>{dataFetch?.title.en}</h1>
                  ) : (
                    <h1>{dataFetch?.title.pt}</h1>
                  )}
                </motion.h1>
                <motion.h2
                  initial={{ opacity: 0.3 }}
                  animate={hoverTypeControl}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                  style={{ marginLeft: "1.25px" }}
                >
                  {t(`types.${dataFetch?.type}`)}
                </motion.h2>
              </Link>
            </motion.div>
          </div>
        </div>

        <Footer bg={"white"} />
      </div>
    </>
  );
}

export default ProjectInfo;
