import React from "react";
import { useState } from "react";
import "../css/Admin.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NavBar_Admin from "../pages/NavBar_Admin";

function Admin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(null);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("https://api.fprovidencia.com/admin/", {
        email,
        password,
      });

      const { token } = response.data;

      // Store the token in local storage
      localStorage.setItem("token", token);

      // Set the token in the component state
      setToken(token);

      // Redirect to the Dashboard page after successful login
      navigate("/Admin/Dashboard");
    } catch (error) {
      console.error("Error during login:", error);
      // Handle login error (e.g., show error message to the user)
    }
  };

  const handleRestrictedRequest = async () => {
    try {
      const token = localStorage.getItem("token");

      // Include the token in the headers for authenticated requests
      const response = await axios.get(
        "https://api.fprovidencia.com/restricted",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Handle the response for the restricted route
      console.log("Restricted Route Response:", response.data);
    } catch (error) {
      console.error("Error during restricted request:", error);
      // Handle error for restricted request (e.g., show error message to the user)
    }
  };

  return (
    <>
      <NavBar_Admin></NavBar_Admin>
      <div className="Admin__Login">
        <div>
          <h1>ADMINISTRATOR LOGIN</h1>
          <form className="Admin__Login__Grid" onSubmit={handleLogin}>
            <input
              onChange={(e) => setEmail(e.target.value)}
              className="Admin_Input"
              type="email"
              placeholder="Email"
            />
            <input
              onChange={(e) => setPassword(e.target.value)}
              className="Admin_Input"
              type="password"
              placeholder="Password"
            />
            <input className="Admin_Button" type="submit" value="entrar" />
          </form>
        </div>
      </div>
    </>
  );
}

export default Admin;
