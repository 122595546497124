import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../css/Admin.css";
import NavBar_Admin from "./NavBar_Admin";

function EditTeam(props) {
  const { id } = useParams();
  const [data, setData] = useState({
    name: "",
    position: "",
    avatar: null,
    bio: { en: "", pt: "" },
  });

  useEffect(() => {
    // Fetch team member data using the ID when the component mounts
    const fetchTeamMemberData = async () => {
      try {
        const response = await axios.get(
          `https://api.fprovidencia.com/Team/getOne/${id}`
        );
        const teamMemberData = response.data; // Assuming your API returns the team member data

        setData({
          name: teamMemberData.name,
          position: teamMemberData.position,
          avatar: null, // You might want to fetch the avatar separately or leave it as null
          bio: { en: teamMemberData.bio.en, pt: teamMemberData.bio.pt },
        });
      } catch (error) {
        console.error("Error fetching team member data:", error);
      }
    };

    fetchTeamMemberData();
  }, [id]);

  const onDataChange = (fieldname, fieldvalue) => {
    setData((previous) => ({ ...previous, [fieldname]: fieldvalue }));
  };

  const submit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("position", data.position);
    formData.append("bio[en]", data.bio.en);
    formData.append("bio[pt]", data.bio.pt);

    if (data.avatar) {
      formData.append("avatar", data.avatar);
    }

    try {
      await axios.put(
        `https://api.fprovidencia.com/Team/editTeam/${id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      alert("Team Member updated");
      // Redirect or handle success as needed
      window.location.href = "/admin/dashboard";
    } catch (error) {
      console.error("Error updating team member:", error);
    }
  };

  return (
    <div className="Admin">
      <NavBar_Admin></NavBar_Admin>
      <div className="Admin__Main__Grid">
        <div className="Admin__Projects__New">
          <div className="Admin__Section">
            <h1 className="Admin__Section__Title">MEMBRO {data.name}</h1>

            <div className="Admin__Section__New">
              <button type="submit" form="form">
                EDITAR MEMBRO
              </button>
            </div>
          </div>

          <div>
            <form id="form" onSubmit={submit} className="Admin__New__Form">
              <div id="Nome" className="New_Form_Input">
                <p>Nome</p>
                <input
                  name="name"
                  type="text"
                  value={data.name}
                  className="New_Admin_Input"
                  onChange={(e) => onDataChange("name", e.target.value)}
                />
              </div>
              <div id="Cargo" className="New_Form_Input">
                <p>Cargo</p>
                <input
                  name="position"
                  type="text"
                  value={data.position}
                  className="New_Admin_Input"
                  onChange={(e) => onDataChange("position", e.target.value)}
                />
              </div>
              <div id="Bio_PT" className="New_Form_Input">
                <p>Biografia PT</p>
                <ReactQuill
                  value={data.bio.pt}
                  style={{
                    color: "white",
                    paddingBottom: "4em",
                    width: "100%",
                    minHeight: "600px",
                  }}
                  className="New_Admin_Input__text"
                  onChange={(value) =>
                    onDataChange("bio", { ...data.bio, pt: value })
                  }
                />
              </div>
              <div id="Bio_EN" className="New_Form_Input">
                <p>Bio EN</p>
                <ReactQuill
                  value={data.bio.en}
                  className="New_Admin_Input__text"
                  style={{
                    paddingBottom: "4em",
                    width: "100%",
                    minHeight: "600px",
                  }}
                  onChange={(value) =>
                    onDataChange("bio", { ...data.bio, en: value })
                  }
                />
              </div>
              <div id="Avatar" className="New_Form_Input">
                <p>Imagem</p>
                <input
                  name="avatar"
                  className="New_Admin_Input"
                  onChange={(e) => onDataChange("avatar", e.target.files)}
                  type="file"
                  accept="image/*, video/*"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditTeam;
