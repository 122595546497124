import { React, useEffect, useState } from "react";
import Logo_W from "../data/logo/Logo_PD_W.svg";
import Logo_B from "../data/logo/Logo_PD_B.svg";
import { HashLink } from "react-router-hash-link";
import { Link, useLocation } from "react-router-dom";

import EU_Logo_Mobile from "../data/logo/EULogoWhite.png";
import MenuOpen from "../data/Icons/Menu.png";
import MenuClose from "../data/Icons/MenuClose.png";
import FooterPortugal2020Mobile from "../data/logo/Logo_Portugal_2020_White.png";
import Norte2020Mobile from "../data/logo/Norte_2020_White.png";

import {
  motion,
  useScroll,
  useMotionValueEvent,
  AnimatePresence,
} from "framer-motion";

import "../css/NavBar.css";

import { useTranslation } from "react-i18next";

const NavBar_Admin = (props) => {
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const location = useLocation();

  const toggleLanguage = () => {
    const currentLanguage = i18n.language;
    const newLanguage = currentLanguage === "en" ? "pt" : "en";

    i18n.changeLanguage(newLanguage);
  };

  const { scrollY } = useScroll();

  const [hidden, setHidden] = useState(false);

  const [logoBlack, setLogoBlack] = useState(false);

  const [activeMenuItem, setActiveMenuItem] = useState(null);

  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious();
    if (latest > previous && latest > 150) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  });

  const logoSrc = logoBlack ? Logo_B : Logo_W;

  return (
    <motion.nav
      variants={{
        visible: { y: 0 },
        hidden: { y: "-100%" },
      }}
      animate={hidden ? "hidden" : "visible"}
      transition={{ duration: 0.5, ease: "easeInOut" }}
      className="NavBar__Animation"
      id="NavBar__Animation"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div id="navBar__New" className="navbar-container__test__Logo">
        <div className="navbar__Grid__Logo">
          <Link to="/">
            <img src={logoSrc} alt="Logo" className="HomeLogo" />
          </Link>
        </div>
      </div>
    </motion.nav>
  );
};

export default NavBar_Admin;
