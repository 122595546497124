import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import loadingLogo from "../data/logo/Logo_PD_B.svg";
import { useTranslation } from "react-i18next";

const ProjectItem = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const { i18n } = useTranslation();

  const currentLanguage = i18n.language

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <motion.div
      className="main__Grid__item"
      onHoverStart={handleHover}
      onHoverEnd={handleMouseLeave}
      whileHover={{ opacity: 1 }}
    >
      <Link to={`/ProjectInfo/${props.data.url}`} state={props.data}>
        <motion.img
          src={
            isImageLoaded
              ? `https://providencia-design-bucket.s3.eu-west-2.amazonaws.com/${props.data.capa}`
              : loadingLogo
          }
          alt="ProjectImage"
          className="card__img blurTeste"
          animate={{
            opacity: isImageLoaded ? 1 : 0,
            filter: isImageLoaded ? "blur(0px)" : "blur(8px)",
          }}
          transition={{ duration: 0.5 }}
          onLoad={handleImageLoad}
        />
        <motion.div
          className="project-title"
          initial={{ opacity: 0 }}
          animate={{
            opacity: isHovered ? 1 : 0,
          }}
          transition={{ duration: 0.3 }}
        >
          {currentLanguage === "en" ? (
            <p>{props.data.title.en}</p>
          ) : (
            <p>{props.data.title.pt}</p>
          )}
        </motion.div>
      </Link>
    </motion.div>
  );
};

export default ProjectItem;
