import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Theory.css";
import { motion, useAnimation } from "framer-motion";
import NavBar from "./NavBar";
import { useTranslation } from "react-i18next";
import Triade from "../data/Triade";

const imagesArray = {
  pt: [
    "/images/Theory/Gráfico_Tripartido/4x/PT/Asset 36@4x.png",
    "/images/Theory/Gráfico_Tripartido/4x/PT/Asset 37@4x.png",
    "/images/Theory/Gráfico_Tripartido/4x/PT/Asset 38@4x.png",
    "/images/Theory/Gráfico_Tripartido/4x/PT/Asset 39@4x.png",
    "/images/Theory/Gráfico_Tripartido/4x/PT/Asset 40@4x.png",
  ],

  en: [
    "/images/Theory/Gráfico_Tripartido/4x/EN/Asset_384x.png",
    "/images/Theory/Gráfico_Tripartido/4x/EN/Asset_394x.png",
    "/images/Theory/Gráfico_Tripartido/4x/EN/Asset_404x.png",
    "/images/Theory/Gráfico_Tripartido/4x/EN/Asset_414x.png",
    "/images/Theory/Gráfico_Tripartido/4x/EN/Asset_424x.png",
  ],
};

const Theory = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [caption, setCaption] = useState("<b>Vitruvio</b>, séc I a. C.");

  const [rotation, setRotation] = useState(0);
  const [imageIndex, setImageIndex] = useState(0);
  const imageRefs = useRef(
    imagesArray[currentLanguage].map(() => React.createRef())
  );
  const [imageOpacities, setImageOpacities] = useState(
    imagesArray[currentLanguage].map((_, index) => {
      if (index === 0) return 1;
      if (index === 1) return 0.1;
      if (index === imagesArray[currentLanguage].length - 1) return 0;
      return 0;
    })
  );
  const [stackVisible, setStackVisibility] = useState(true); // State to control visibility

  const ControlTheory = useAnimation();

  const setImageOpacity = (newIndex) => {
    const newOpacities = imagesArray[currentLanguage].map((_, index) => {
      if (index === newIndex) return 1;
      if (index === (newIndex + 1) % imagesArray[currentLanguage].length)
        return 0.1;
      if (
        index ===
        (newIndex - 1 + imagesArray[currentLanguage].length) %
          imagesArray[currentLanguage].length
      ) {
        return 0.2;
      }
      if (
        newIndex === imagesArray[currentLanguage].length - 1 &&
        (index === 0 || index === imagesArray[currentLanguage].length - 2)
      ) {
        return 0.2;
      }

      // Special condition for the 5th image and the two images before it
      if (
        imagesArray[currentLanguage][newIndex] === "/images/Asset 40@4x.png"
      ) {
        if (
          index === newIndex ||
          index ===
            (newIndex - 1 + imagesArray[currentLanguage].length) %
              imagesArray[currentLanguage].length ||
          index ===
            (newIndex - 2 + imagesArray[currentLanguage].length) %
              imagesArray[currentLanguage].length
        ) {
          return 0.2;
        }
      }

      return 0;
    });

    setImageIndex(newIndex);
    setImageOpacities(newOpacities);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Get the current scroll position
      const scrollY = window.scrollY;

      // Calculate the number of 100-pixel increments
      const increments = Math.floor(scrollY / 150);

      // Calculate the rotation angle based on increments
      const newRotation = increments * -30;

      // Update the rotation angle
      setRotation(newRotation);

      // Determine the current image index based on increments
      const newIndex = increments % imagesArray[currentLanguage].length;

      if (newIndex === 0 || newIndex === 1) {
        setCaption("<b>Vitruvio</b>, séc I a. C.");
      } else {
        setCaption("<b>Providência</b>, 1990");
      }

      if (newIndex !== imageIndex) {
        // Scroll to the new image smoothly
        imageRefs.current[newIndex].current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        // Update the opacity
        setImageOpacity(newIndex);
      }

      // Special condition for scrolling back to the first image
      if (newIndex === 0) {
        // If newIndex is 0, it means the user has scrolled past the entire gallery
        // Set the opacity of the first image to 1 (100%) and others to 0
        setImageOpacities([1, 0.1, 0, 0, 0]);
      }

      if (newIndex === 0 && rotation === -150) {
        setCaption("<b>Providência</b>, 1990");
        window.removeEventListener("scroll", handleScroll);
        setImageOpacities([0, 0, 0, 0, 1]);
        ControlTheory.start({
          opacity: 0,
          transition: { duration: 1, ease: "easeOut" },
        });
        setTimeout(() => {
          navigate("/TheoryBrief");
        }, 1000);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Remove the scroll event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, [imageIndex]);

  const imageStyle = {
    transform: `rotate(${rotation}deg)`,
    position: "fixed",
    transformOrigin: "center center",
    zIndex: 1, // To stack images on top of each other
  };

  const images = imagesArray[currentLanguage].map((src, index) => (
    <div
      key={index}
      className="image-container"
      style={{
        ...imageStyle,
        opacity: imageOpacities[index],
      }}
      ref={imageRefs.current[index]}
    >
      <img
        src={src}
        alt={`Rotating Image ${index + 1}`}
        className="rotating-image"
      />
    </div>
  ));

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <NavBar />

      <motion.div animate={ControlTheory} className="Theory">
        {stackVisible ? (
          images
        ) : (
          <div style={{ display: "none" }}>
            {imagesArray.map((_, index) => (
              <div
                key={index}
                className="image-container"
                style={{ ...imageStyle, opacity: 0 }}
              >
                <img
                  src={imagesArray[index]}
                  alt={`Rotating Image ${index + 1}`}
                  className="rotating-image"
                />
              </div>
            ))}
          </div>
        )}
      </motion.div>

      <div className="Theory__Caption2">
        <p>{t("theoryAforismos.scroll")}</p>
      </div>

      <div className="Theory__Caption">
        <p dangerouslySetInnerHTML={{ __html: caption }}></p>
      </div>
    </motion.div>
  );
};

export default Theory;
