import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../css/Admin.css";
import NavBar_Admin from "./NavBar_Admin";
import ReactQuill from "react-quill"; // Import the ReactQuill component
import "react-quill/dist/quill.snow.css"; // Import the styles for the editor

function EditProject() {
  const { id } = useParams();
  const [data, setData] = useState({
    url: "",
    title: { en: "", pt: "" },
    type: "",
    cover: "",
    capa: "",
    awards_img: [],
    content: [],
    content_text: {
      body: { en: "", pt: "" },
      title: { en: "", pt: "" },
    },
    info: {
      client: { en: "", pt: "" },
      year: 0,
      team: { en: "", pt: "" },
      awards_pt: [""],
      awards_en: [""],
    },
    featured: false,
  });

  useEffect(() => {
    // Fetch team member data using the ID when the component mounts
    const fetchTeamMemberData = async () => {
      try {
        const response = await axios.get(
          `https://api.fprovidencia.com/Project/getOne/${id}`
        );
        const projectData = response.data; // Assuming your API returns the team member data

        setData({
          url: projectData.url,
          title: { en: projectData.title.en, pt: projectData.title.pt },
          type: projectData.type,
          cover: projectData.cover,
          capa: projectData.capa,
          awards_img: [projectData.awards_img],
          content: [projectData.content],
          content_text: {
            body: {
              en: projectData.content_text?.body?.en || "",
              pt: projectData.content_text?.body?.pt || "",
            },
            title: {
              en: projectData.content_text?.title?.en || "",
              pt: projectData.content_text?.title?.pt || "",
            },
          },
          info: {
            client: {
              en: projectData.info.client.en,
              pt: projectData.info.client.pt,
            },
            year: projectData.info.year,
            team: {
              en: projectData.info.team.en,
              pt: projectData.info.team.pt,
            },
            awards_en: projectData.info.awards_en.map((award) => award),
            awards_pt: projectData.info.awards_pt.map((award) => award),
          },
          featured: false,
        });
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchTeamMemberData();
  }, []);

  const onDataChange = (fieldname, fieldvalue) => {
    setData((previous) => ({ ...previous, [fieldname]: fieldvalue }));
  };

  // Handle changes in awards_pt input
  const handleAwardsInputChange_pt = (index, value) => {
    setData((prevData) => ({
      ...prevData,
      info: {
        ...prevData.info,
        awards_pt: prevData.info.awards_pt.map((award, i) =>
          i === index ? value : award
        ),
      },
    }));
  };

  // Handle adding awards_pt input
  const handleAddAwardsInput_pt = () => {
    setData((prevData) => ({
      ...prevData,
      info: {
        ...prevData.info,
        awards_pt: [...prevData.info.awards_pt, ""],
      },
    }));
  };

  // Handle removing awards_pt input
  const handleRemoveAwardsInput_pt = (index) => {
    setData((prevData) => ({
      ...prevData,
      info: {
        ...prevData.info,
        awards_pt: prevData.info.awards_pt.filter((_, i) => i !== index),
      },
    }));
  };

  // Handle changes in awards_en input
  const handleAwardsInputChange_en = (index, value) => {
    setData((prevData) => ({
      ...prevData,
      info: {
        ...prevData.info,
        awards_en: prevData.info.awards_en.map((award, i) =>
          i === index ? value : award
        ),
      },
    }));
  };

  // Handle adding awards_en input
  const handleAddAwardsInput_en = () => {
    setData((prevData) => ({
      ...prevData,
      info: {
        ...prevData.info,
        awards_en: [...prevData.info.awards_en, ""],
      },
    }));
  };

  // Handle removing awards_en input
  const handleRemoveAwardsInput_en = (index) => {
    setData((prevData) => ({
      ...prevData,
      info: {
        ...prevData.info,
        awards_en: prevData.info.awards_en.filter((_, i) => i !== index),
      },
    }));
  };

  const submit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("title[en]", data.title.en);
    formData.append("title[pt]", data.title.pt);
    formData.append("content_text[title][pt]", data.content_text.title.pt);
    formData.append("content_text[body][pt]", data.content_text.body.pt);
    formData.append("content_text[title][en]", data.content_text.title.en);
    formData.append("content_text[body][en]", data.content_text.body.en);
    formData.append("type", data.type);
    formData.append("info[client][pt]", data.info.client.pt);
    formData.append("info[client][en]", data.info.client.en);
    formData.append("info[year]", data.info.year);
    formData.append("info[team][pt]", data.info.team.pt);
    formData.append("info[team][en]", data.info.team.en);

    if (data.info.awards_en.length === 0) {
      formData.append("info[awards_en][]", []);
    }

    if (data.info.awards_pt.length === 0) {
      formData.append("info[awards_pt][]", []);
    }

    for (let i = 0; i < data.info.awards_en.length; i++) {
      formData.append("info[awards_en][]", data.info.awards_en[i]);
    }

    for (let i = 0; i < data.info.awards_pt.length; i++) {
      formData.append("info[awards_pt][]", data.info.awards_pt[i]);
    }

    for (let i = 0; i < data.cover.length; i++) {
      formData.append("cover", data.cover[i]);
    }

    for (let i = 0; i < data.capa.length; i++) {
      formData.append("capa", data.capa[i]);
    }

    for (let i = 0; i < data.awards_img.length; i++) {
      formData.append("awards_img", data.awards_img[i]);
    }

    for (let i = 0; i < data.content.length; i++) {
      formData.append("content", data.content[i]);
    }

    try {
      await axios.put(
        `https://api.fprovidencia.com/project/editProject/${id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      alert("Project updated");
      // Redirect or handle success as needed
      window.location.href = "/admin/dashboard";
    } catch (error) {
      console.error("Error updating project:", error);
    }
  };

  // axios
  //   .get("https://api.fprovidencia.com/project")
  //   .then(function (response) {
  //     // handle success
  //   })
  //   .catch(function (error) {
  //     // handle error
  //     console.log(error);
  //   });
  return (
    <div className="Admin">
      <NavBar_Admin></NavBar_Admin>
      <div className="Admin__Main__Grid">
        <div className="Admin__Projects__New">
          <div className="Admin__Section">
            <h1 className="Admin__Section__Title">Projeto {data.title.pt}</h1>

            <div className="Admin__Section__New">
              <button type="submit" form="form">
                EDITAR PROJETO
              </button>
            </div>
          </div>

          <div>
            <form id="form" onSubmit={submit} className="Admin__New__Form">
              <div id="Title_PT" className="New_Form_Input">
                <p>Título PT *</p>
                <input
                  name="title"
                  type="text"
                  className="New_Admin_Input"
                  value={data.title.pt}
                  onChange={(e) =>
                    onDataChange("title", { ...data.title, pt: e.target.value })
                  }
                />
              </div>
              <div id="Title_EN" className="New_Form_Input">
                <p>Título EN *</p>
                <input
                  name="title"
                  type="text"
                  className="New_Admin_Input"
                  value={data.title.en}
                  onChange={(e) =>
                    onDataChange("title", { ...data.title, en: e.target.value })
                  }
                />
              </div>
              <div id="Type" className="New_Form_Input">
                <p>Categoria *</p>
                <select
                  name="type"
                  className="New__Project__Select"
                  onChange={(e) => onDataChange("type", e.target.value)}
                  value={data.type || ""}
                >
                  <option value="">Select One</option>
                  <option value="Editorial">Editorial</option>
                  <option value="Posters">Cartazes</option>
                  <option value="Branding">Identidade</option>
                  <option value="Museography">Museografia</option>
                  <option value="Product">Produto</option>
                  <option value="Signage">Sinalização</option>
                </select>
              </div>
              <div id="Client_PT" className="New_Form_Input">
                <p>Client PT *</p>
                <input
                  name="client"
                  type="text"
                  className="New_Admin_Input"
                  value={data.info.client.pt}
                  onChange={(e) =>
                    onDataChange("info", {
                      ...data.info,
                      client: {
                        ...data.info.client,
                        pt: e.target.value,
                      },
                    })
                  }
                />
              </div>
              <div id="Client_EN" className="New_Form_Input">
                <p>Client EN *</p>
                <input
                  name="client"
                  type="text"
                  className="New_Admin_Input"
                  value={data.info.client.en}
                  onChange={(e) =>
                    onDataChange("info", {
                      ...data.info,
                      client: {
                        ...data.info.client,
                        en: e.target.value,
                      },
                    })
                  }
                />
              </div>
              <div id="Capa" className="New_Form_Input">
                <p>Capa Mosaíco *</p>
                <input
                  name="capa"
                  className="New_Admin_Input"
                  onChange={(e) => onDataChange("capa", e.target.files)}
                  type="file"
                  accept="image/*, video/*"
                />
              </div>
              <div id="Destaque" className="New_Form_Input">
                <p>Capa Destaque *</p>
                <input
                  name="cover"
                  className="New_Admin_Input"
                  onChange={(e) => onDataChange("cover", e.target.files)}
                  type="file"
                  accept="image/*, video/*"
                />
              </div>
              <div id="Medalhas" className="New_Form_Input">
                <p>Medalhas Prémios</p>
                <input
                  name="awards_img"
                  className="New_Admin_Input"
                  onChange={(e) => onDataChange("awards_img", e.target.files)}
                  type="file"
                  accept="image/*, video/*"
                  multiple="multiple"
                />
              </div>
              <div id="Content" className="New_Form_Input">
                <p>Imagens do Projeto *</p>
                <input
                  name="content"
                  className="New_Admin_Input"
                  onChange={(e) => onDataChange("content", e.target.files)}
                  type="file"
                  accept="image/*, video/*"
                  multiple="multiple"
                />
              </div>

              <div id="Quote_title_PT" className="New_Form_Input">
                <p>Citação Título PT</p>
                <input
                  name="content_text.title.pt"
                  className="New_Admin_Input"
                  value={data.content_text.title?.pt}
                  onChange={(e) =>
                    onDataChange("content_text", {
                      ...data.content_text,
                      title: {
                        ...data.content_text.title,
                        pt: e.target.value,
                      },
                    })
                  }
                  type="text"
                />
              </div>

              <div id="Quote_body_PT" className="New_Form_Input">
                <p>Citação Corpo PT</p>
                <input
                  name="content_text.body.pt"
                  className="New_Admin_Input"
                  value={data.content_text.body?.pt}
                  onChange={(e) =>
                    onDataChange("content_text", {
                      ...data.content_text,
                      body: {
                        ...data.content_text.body,
                        pt: e.target.value,
                      },
                    })
                  }
                  type="text"
                />
              </div>

              <div id="Quote_title_EN" className="New_Form_Input">
                <p>Citação Título EN</p>
                <input
                  name="content_text.title.en"
                  className="New_Admin_Input"
                  value={data.content_text.title?.en}
                  onChange={(e) =>
                    onDataChange("content_text", {
                      ...data.content_text,
                      title: {
                        ...data.content_text.title,
                        en: e.target.value,
                      },
                    })
                  }
                  type="text"
                />
              </div>

              <div id="Quote_body_EN" className="New_Form_Input">
                <p>Citação Corpo EN</p>
                <input
                  name="content_text.body.en"
                  className="New_Admin_Input"
                  value={data.content_text.body?.en}
                  onChange={(e) =>
                    onDataChange("content_text", {
                      ...data.content_text,
                      body: {
                        ...data.content_text.body,
                        en: e.target.value,
                      },
                    })
                  }
                  type="text"
                />
              </div>

              <div id="Year" className="New_Form_Input">
                <p>Ano do Projeto *</p>
                <input
                  name="year"
                  type="number"
                  className="New_Admin_Input"
                  value={data.info.year}
                  onChange={(e) =>
                    onDataChange("info", { ...data.info, year: e.target.value })
                  }
                />
              </div>
              <div id="Equipa_PT" className="New_Form_Input">
                <p>Equipa PT *</p>
                <ReactQuill
                  name="team"
                  type="text"
                  className="New_Admin_Input__text"
                  value={data.info.team.pt}
                  style={{
                    paddingBottom: "4em",
                    width: "100%",
                    minHeight: "600px",
                  }}
                  onChange={(content) =>
                    onDataChange("info", {
                      ...data.info,
                      team: {
                        ...data.info.team,
                        pt: content,
                      },
                    })
                  }
                />
              </div>
              <div id="Equipa_EN" className="New_Form_Input">
                <p>Equipa EN *</p>
                <ReactQuill
                  name="team"
                  type="text"
                  className="New_Admin_Input__text"
                  value={data.info.team.en}
                  style={{
                    paddingBottom: "4em",
                    width: "100%",
                    minHeight: "600px",
                  }}
                  onChange={(content) =>
                    onDataChange("info", {
                      ...data.info,
                      team: {
                        ...data.info.team,
                        en: content,
                      },
                    })
                  }
                />
              </div>
              <div id="Awards_PT" className="New_Form_Input">
                <p>Prémios PT</p>
                {data.info.awards_pt.map((award_pt, index) => (
                  <div key={index}>
                    <input
                      type="text"
                      className="New_Admin_Input"
                      value={award_pt}
                      onChange={(e) =>
                        handleAwardsInputChange_pt(index, e.target.value)
                      }
                    />
                    <br></br>
                    <button
                      type="button"
                      className="Admin_Button"
                      style={{ marginBottom: "25px" }}
                      onClick={() => handleRemoveAwardsInput_pt(index)}
                    >
                      REMOVER PRÉMIO PT
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="Admin_Button"
                  onClick={handleAddAwardsInput_pt}
                >
                  ADICIONAR PRÉMIO PT
                </button>
              </div>
              <div id="Awards_EN" className="New_Form_Input">
                <p>Prémios EN</p>
                {data.info.awards_en.map((award_en, index) => (
                  <div key={index}>
                    <input
                      type="text"
                      className="New_Admin_Input"
                      value={award_en}
                      onChange={(e) =>
                        handleAwardsInputChange_en(index, e.target.value)
                      }
                    />
                    <br></br>
                    <button
                      type="button"
                      className="Admin_Button"
                      style={{ marginBottom: "25px" }}
                      onClick={() => handleRemoveAwardsInput_en(index)}
                    >
                      REMOVER PRÉMIO EN
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="Admin_Button"
                  onClick={handleAddAwardsInput_en}
                >
                  ADICIONAR PRÉMIO EN
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProject;
