import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminProjectItem from "./AdminProjectItem";
import { Link } from "react-router-dom";

function AdminProjects() {
  const [ProjectsData, setProjectsData] = useState();

  useEffect(() => {
    const fetchProjects = async () => {
      await axios
        .get("https://api.fprovidencia.com/project")
        .then(function (response) {
          // handle success
          setProjectsData(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    };

    fetchProjects();
  }, []);

  return (
    <div id="Projects" className="Admin__Main__Grid__Child">
      <div className="Admin__Section">
        <h1 className="Admin__Section__Title">PROJETOS</h1>

        <Link className="Admin__Section__New" to="/Admin/Dashboard/NewProject">
          <button>NOVO PROJETO</button>
        </Link>
      </div>
      <div className="Admin__Projects__Grid">
        {ProjectsData?.map((data, index) => (
          <AdminProjectItem
            key={index}
            data={data}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}

export default AdminProjects;
