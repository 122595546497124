import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import bg_main from "../data/bg/stickMan.png";
import emailIcon from "../data/Icons/mail.png";
import locationIcon from "../data/Icons/location.png";
import behanceIcon from "../data/Icons/behance.png";
import linkedInIcon from "../data/Icons/linkedin.png";
import vimeoIcon from "../data/Icons/vimeo.png";
import instagramIcon from "../data/Icons/instagram.png";
import facebookIcon from "../data/Icons/facebook.png";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import "../css/Contacts.css";
import NavBar from "./NavBar";

function Contacts() {
  const { t } = useTranslation();

  const sendMail = () => {
    window.location = "mailto:correio@fprovidencia.com";
  };

  const handleOpenGoogleMaps = () => {
    const address = "Rua do Bicalho, 123 4150-139 Porto, Portugal";
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;

    window.open(googleMapsUrl, "_blank");
  };

  const handleOpenBehance = () => {
    const behanceUrl = "https://www.behance.net/providenciadesign";
    window.open(behanceUrl, "_blank");
  };

  const handleOpenLinkedIn = () => {
    const linkedInUrl =
      "https://www.linkedin.com/company/provid%C3%AAnciadesign/";
    window.open(linkedInUrl, "_blank");
  };

  const handleOpenInstagram = () => {
    const InstagramUrl = "https://www.instagram.com/providenciadesign/";
    window.open(InstagramUrl, "_blank");
  };

  return (
    <>
      <div className="bg-black">
        <section
          id="section"
          style={{
            "--img": `url(${bg_main})`,
            zIndex: "100",
            height: "100svh",
            overflow: "hidden",
          }}
        >
          <NavBar />

          <div className="contacts__Grid">
            <div className="contacts__items__grid">
              <div className="contacts__left__items">
                <h1>{t("contacts.1")}</h1>
                <motion.div
                  style={{ cursor: "pointer" }}
                  initial={{ opacity: 0.4 }}
                  whileHover={{ opacity: 0.95 }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                  onClick={sendMail}
                >
                  <img src={emailIcon}></img>
                  <p>correio@fprovidencia.com +351 226 061 790</p>
                </motion.div>
              </div>
              <div className="contacts__middle__items">
                <h1>{t("contacts.2")}</h1>
                <motion.div
                  style={{ cursor: "pointer" }}
                  initial={{ opacity: 0.4 }}
                  whileHover={{ opacity: 0.95 }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                  onTap={handleOpenGoogleMaps}
                >
                  <img src={locationIcon}></img>
                  <p>Rua do Bicalho, 123 4150-139 Porto, Portugal</p>
                </motion.div>
              </div>
              <div className="contacts__right__items">
                <h1>{t("contacts.3")}</h1>
                <div className="contacts__contacts__Grid">
                  <motion.div
                    style={{ cursor: "pointer" }}
                    initial={{ opacity: 0.4 }}
                    whileHover={{ opacity: 0.95 }}
                    transition={{ duration: 0.5, ease: "easeOut" }}
                    onTap={handleOpenBehance}
                  >
                    <img src={behanceIcon}></img>
                    <p>Behance</p>
                  </motion.div>
                  <motion.div
                    style={{ cursor: "pointer" }}
                    initial={{ opacity: 0.4 }}
                    whileHover={{ opacity: 0.95 }}
                    transition={{ duration: 0.5, ease: "easeOut" }}
                    onTap={handleOpenLinkedIn}
                  >
                    <img src={linkedInIcon}></img>
                    <p>LinkedIn</p>
                  </motion.div>
                  <motion.div
                    style={{ cursor: "pointer" }}
                    initial={{ opacity: 0.4 }}
                    whileHover={{ opacity: 0.95 }}
                    transition={{ duration: 0.5, ease: "easeOut" }}
                    onTap={handleOpenInstagram}
                  >
                    <img src={instagramIcon}></img>
                    <p>Instagram</p>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>

          <div className="contacts__Grid__mobile">
            <div className="contacts__left__items__mobile">
              <div className="contacts__Mobile__icons">
                <h1>{t("contacts.1")}</h1>
                <img onClick={sendMail} src={emailIcon}></img>
              </div>
              <p onClick={sendMail}>
                correio@fprovidencia.com<br></br> +351 226 061 790
              </p>
            </div>
            <div className="contacts__middle__items__mobile">
              <div className="contacts__Mobile__icons">
                <h1>{t("contacts.2")}</h1>
                <img onClick={handleOpenGoogleMaps} src={locationIcon}></img>
              </div>
              <p onClick={handleOpenGoogleMaps}>
                Rua do Bicalho, 123<br></br> 4150-139 Porto, Portugal
              </p>
            </div>

            <div className="contacts__right__items__mobile">
              <div className="contacts__contacts__item__mobile__left">
                <div>
                  <h1>{t("contacts.3")}</h1>
                </div>
                <div className="yaya">
                  <img onClick={handleOpenBehance} src={behanceIcon}></img>

                  <img onClick={handleOpenLinkedIn} src={linkedInIcon}></img>

                  <img onClick={handleOpenInstagram} src={instagramIcon}></img>
                </div>
              </div>
              <div className="contacts__contacts__item__mobile">
                <p onClick={handleOpenBehance}>Behance</p>
                <p onClick={handleOpenLinkedIn}>LinkedIn</p>
                <p onClick={handleOpenInstagram}>Instagram</p>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "100vh",
              width: "100vw",
              position: "absolute",
              bottom: 0,
              left: 0,
              display: "grid",
              alignItems: "end",
            }}
          >
            <Footer bg={"black"} contacts={"true"} />
          </div>
        </section>
      </div>
    </>
  );
}

export default Contacts;
