import React, { useState } from "react";
import axios from "axios";
import "../css/Admin.css";
import NavBar_Admin from "./NavBar_Admin";

function NewAward2(props) {
  const [data, setData] = useState({
    title: { pt: "", en: "" },
    type: { pt: "", en: "" },
    year: "",
  });

  const onDataChange = (fieldname, fieldvalue) => {
    setData((previous) => ({ ...previous, [fieldname]: fieldvalue }));
  };

  const submit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("title[pt]", data.title.pt);
    formData.append("title[en]", data.title.en);
    formData.append("type[pt]", data.type.pt);
    formData.append("type[en]", data.type.en);
    formData.append("year", data.year);

    await axios.post(
      "https://api.fprovidencia.com/Award_2/newAward",
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    alert("New Award added");
    window.location.href = "/admin/dashboard";
  };

  return (
    <div className="Admin">
      <NavBar_Admin></NavBar_Admin>
      <div className="Admin__Main__Grid">
        <div className="Admin__Projects__New">
          <div className="Admin__Section">
            <h1 className="Admin__Section__Title">NOVO PRÉMIO</h1>

            <div className="Admin__Section__New">
              <button type="submit" form="form">
                CRIAR PRÉMIO
              </button>
            </div>
          </div>

          <div>
            <form id="form" onSubmit={submit} className="Admin__New__Form">
              <div id="Title_PT" className="New_Form_Input">
                <p>Title PT</p>
                <input
                  value={data.title.pt}
                  name="title"
                  type="text"
                  className="New_Admin_Input"
                  onChange={(e) =>
                    onDataChange("title", { ...data.title, pt: e.target.value })
                  }
                />
              </div>
              <div id="Title_EN" className="New_Form_Input">
                <p>Title EN</p>
                <input
                  value={data.title.en}
                  name="title"
                  type="text"
                  className="New_Admin_Input"
                  onChange={(e) =>
                    onDataChange("title", { ...data.title, en: e.target.value })
                  }
                />
              </div>
              <div id="Type_PT" className="New_Form_Input">
                <p>Type PT</p>
                <input
                  value={data.type.pt}
                  name="type"
                  type="text"
                  className="New_Admin_Input"
                  onChange={(e) =>
                    onDataChange("type", { ...data.type, pt: e.target.value })
                  }
                />
              </div>
              <div id="Type_EN" className="New_Form_Input">
                <p>Type EN</p>
                <input
                  value={data.type.en}
                  name="type"
                  type="text"
                  className="New_Admin_Input"
                  onChange={(e) =>
                    onDataChange("type", { ...data.type, en: e.target.value })
                  }
                />
              </div>
              <div id="Year_Awards2" className="New_Form_Input">
                <p>Year</p>
                <input
                  name="year"
                  type="number"
                  className="New_Admin_Input"
                  onChange={(e) => onDataChange("year", e.target.value)}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewAward2;
