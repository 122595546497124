import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminAwardsItem from "./AdminAwardsItem";
import { Link } from "react-router-dom";

function AdminAwards2(props) {
  const [awardsData, setAwardsData] = useState();

  useEffect(() => {
    const fetchProjects = async () => {
      await axios
        .get("https://api.fprovidencia.com/Award_2/")
        .then(function (response) {
          // handle success
          setAwardsData(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    };

    fetchProjects();
  }, []);

  return (
    <div id="Awards2" className="Admin__Main__Grid__Child">
      <div className="Admin__Section">
        <h1 className="Admin__Section__Title">
          Prémios em Concursos Nacionais e Internacionais
        </h1>

        <Link className="Admin__Section__New" to="/Admin/Dashboard/NewAward2">
          <button>NOVO PRÉMIO</button>
        </Link>
      </div>
      <div className="Admin__Projects__Grid">
        {awardsData?.map((data, index) => (
          <AdminAwardsItem data={data} index={index} type={2} />
        ))}
      </div>
    </div>
  );
}

export default AdminAwards2;
