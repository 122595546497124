import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import axios from "axios";

import "../css/Awards.css";
import AwardsItem from "./AwardsItem";
import NavBar from "./NavBar";

import { useTranslation } from "react-i18next";

function Awards() {
  const { t } = useTranslation();

  const [dataFetch, setDataFetch] = useState();
  const [dataFetch2, setDataFetch2] = useState();

  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    if (isHover === true) {
      document.getElementsByClassName("Awards__Line")[0].style.borderColor =
        "red";
    } else {
      document.getElementsByClassName("Awards__Line")[0].style.borderColor =
        "white";
    }
  }, [isHover]);

  useEffect(() => {
    const request = async () => {
      await axios
        .get("https://api.fprovidencia.com/Award_1")
        .then((response) => {
          setDataFetch(response.data);
        });
    };
    request();
    const request2 = async () => {
      await axios
        .get("https://api.fprovidencia.com/Award_2")
        .then((response) => {
          setDataFetch2(response.data);
        });
    };
    request2();
  }, []);

  document.addEventListener("DOMContentLoaded", function (event) {
    document.querySelector("body").style.opacity = 1;
  });

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const handleAwardsClickAdd = () => {
    scrollToTop();
    document.getElementById("Awards__Line").classList.add("Awards__active");
    document.getElementById("Awards__Title__2").classList.remove("inactive");
    document.getElementById("Awards__Title__1").classList.add("inactive");
    document.getElementById("Awards__Info__1").style.display = "none";
    document.getElementById("Awards__Info__2").style.opacity = "0%";
    document.getElementById("Awards__Info__2").style.transition = "1s";
    document.getElementById("Awards__Info__2").style.display = "grid";
    setTimeout(() => {
      document.getElementById("Awards__Info__2").style.opacity = "100%";
    }, 1);
  };

  const handleAwardsClickRemove = () => {
    scrollToTop();
    document.getElementById("Awards__Line").classList.remove("Awards__active");
    document.getElementById("Awards__Line").classList.add("teste2Awards");
    document.getElementById("Awards__Title__1").classList.remove("inactive");
    document.getElementById("Awards__Title__2").classList.add("inactive");
    setTimeout(() => {
      document.getElementById("Awards__Line").classList.remove("teste2Awards");
    }, 500);
    document.getElementById("Awards__Info__2").style.display = "none";
    document.getElementById("Awards__Info__1").style.opacity = "0%";
    document.getElementById("Awards__Info__1").style.transition = "1s";
    document.getElementById("Awards__Info__1").style.display = "grid";
    setTimeout(() => {
      document.getElementById("Awards__Info__1").style.opacity = "100%";
    }, 1);
  };

  return (
    <>
      <div className="bg-black">
        <NavBar bg="black" />

        <div className="gradient__section"></div>

        <main>
          <div className="Awards__Grid">
            <div className="Awards__Title">
              <div className="Awards__Title__Item">
                <span className="Awards__Line" id="Awards__Line"></span>
                <h1
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    lineHeight: "25px",
                  }}
                  id="Awards__Title__1"
                  onClick={handleAwardsClickRemove}
                  onMouseEnter={() => {
                    setIsHover(true);
                  }}
                  onMouseLeave={() => {
                    setIsHover(false);
                  }}
                >
                  {t("awards.1.1")}
                  <br></br>
                  {t("awards.1.2")}
                </h1>
              </div>
              <div className="Awards__Title__Item">
                <h1
                  id="Awards__Title__2"
                  className="inactive"
                  onClick={handleAwardsClickAdd}
                  onMouseEnter={() => {
                    setIsHover(true);
                  }}
                  onMouseLeave={() => {
                    setIsHover(false);
                  }}
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    lineHeight: "25px",
                  }}
                >
                  {t("awards.2.1")}
                  <br></br>
                  {t("awards.2.2")}
                </h1>
              </div>
            </div>
            <div id="Awards__Info__1" className="Awards__Info">
              {dataFetch?.map((data, index, array) => (
                <AwardsItem
                  data={data}
                  showYear={index === 0 || data.year !== array[index - 1].year}
                  key={index}
                  type={1}
                />
              ))}
            </div>

            <div id="Awards__Info__2" className="Awards__Info">
              {dataFetch2?.map((data, index, array) => (
                <AwardsItem
                  data={data}
                  showYear={index === 0 || data.year !== array[index - 1].year}
                  key={index}
                  type={2}
                />
              ))}
            </div>
          </div>
        </main>

        <div className="gradient__section2"></div>

        <Footer bg={"black"} />
      </div>
    </>
  );
}

export default Awards;
