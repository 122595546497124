import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { useMediaQuery } from "react-responsive";

import { motion, useScroll, useMotionValueEvent } from "framer-motion";
import NavBar from "./NavBar";
import TeamItem from "./TeamItem";
import TeamItemMobile from "./TeamItemMobile";

function Team(props) {
  const [dataFetch, setDataFetch] = useState();

  const { scrollY } = useScroll();

  const isMobileS = useMediaQuery({ query: "(max-width: 320px)" });
  const isMobileM = useMediaQuery({
    minWidth: 321,
    maxWidth: 375,
  });
  const isMobileL = useMediaQuery({
    minWidth: 376,
    maxWidth: 425,
  });
  const isTablet = useMediaQuery({
    minWidth: 426,
    maxWidth: 768,
  });
  const isLaptop = useMediaQuery({
    minWidth: 769,
    maxWidth: 1024,
  });
  const isLaptopL = useMediaQuery({
    minWidth: 1025,
    maxWidth: 1440,
  });
  const isDesktop = useMediaQuery({
    minWidth: 1441,
    maxWidth: 1920,
  });

  const isWide = useMediaQuery({
    minWidth: 1921,
  });

  const [hidden, setHidden] = useState(false);

  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious();
    if (latest > previous && latest > 1000) {
      setHidden(true);
    } else if (latest < previous && latest < 1400) {
      setHidden(false);
    }
  });

  useEffect(() => {
    const request = async () => {
      await axios.get("https://api.fprovidencia.com/team").then((response) => {
        setDataFetch(response.data);
      });
    };
    request();
  }, []);

  const ref = useRef(null);

  const handleHover = (event) => {
    const item = event.currentTarget;
    const index = Array.from(item.parentNode.children).indexOf(item);
    const rowIndex = Math.floor(index / 4) + 1; // Calculate the row index
    const colIndex = (index % 4) + 1; // Calculate the column index

    // Add your hover effect here
    if (colIndex === 1) {
      item.style.gridColumn = "1 / 3";
    } else if (colIndex === 2) {
      item.style.gridColumn = "2 / 4";
    } else if (colIndex === 3) {
      item.style.gridColumn = "2 / 4";
    } else if (colIndex === 4) {
      item.style.gridColumn = "3 / 5";
    }

    // Set grid-rows dynamically based on the row index
    item.style.gridRow = `${rowIndex} / ${rowIndex + 2}`;
  };

  const handleMouseOut = (event) => {
    const item = event.currentTarget;
    if (item.style) {
      item.style.gridColumn = "";
      item.style.gridRow = ""; // Reset the grid-row style
    }
  };

  const resetGridStyles = () => {
    // Get all items in the grid and reset their styles
    const gridItems = document.querySelectorAll(".team-grid-item");
    gridItems.forEach((item) => {
      if (item.style) {
        item.style.gridColumn = "";
        item.style.gridRow = "";
      }
    });
  };

  const handleClick = (event) => {
    const item = event.currentTarget;

    // Reset styles for all grid items
    resetGridStyles();

    const index = Array.from(item.parentNode.children).indexOf(item);
    const rowIndex = Math.floor(index / 2) + 1; // Calculate the row index
    const colIndex = (index % 2) + 1; // Calculate the column index

    // Add your hover effect here
    if (colIndex === 1) {
      item.style.gridColumn = "1 / 3";
      item.style.gridRow = "1 / 3";
    } else if (colIndex === 2) {
      item.style.gridColumn = "1 / 3";
      item.style.gridRow = "1 / 3";
    }

    // // Set grid-rows dynamically based on the row index
    // item.style.gridRow = `${rowIndex} / ${rowIndex + 2}`;
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      // Check if the clicked element is not within the current item
      if (!event.target.closest(".team__item")) {
        // Reset styles for all grid items
        resetGridStyles();
      }
    };

    document.body.addEventListener("click", handleDocumentClick);

    return () => {
      document.body.removeEventListener("click", handleDocumentClick);
    };
  }, [resetGridStyles]);

  return (
    <div className="bg-black">
      <NavBar />
      {isWide || isDesktop || isLaptopL ? (
        <motion.div className="team__Grid" ref={ref}>
          {dataFetch?.map((data) => (
            <div className="team-grid-item" key={data.id} onClick={handleClick}>
              <TeamItemMobile data={data} />
            </div>
          ))}
        </motion.div>
      ) : (
        <motion.div className="team__Grid" ref={ref}>
          {dataFetch?.map((data) => (
            <div className="team-grid-item" key={data.id} onClick={handleClick}>
              <TeamItemMobile data={data} />
            </div>
          ))}
        </motion.div>
      )}
    </div>
  );
}

export default Team;
