import React, { useEffect } from "react";
import "../css/Admin.css";
import Sidenav from "./Sidenav";
import AdminProjects from "./AdminProjects";
import AdminTeam from "./AdminTeam";
import AdminAwards from "./AdminAwards";
import AdminAwards2 from "./AdminAwards2";
import axios from "axios";
import NavBar_Admin from "./NavBar_Admin";

function Dashboard() {
  return (
    <div className="Admin">
      <NavBar_Admin className="ScreenTop" bg="black" />
      <Sidenav />

      <div className="Admin__Main__Grid">
        <AdminProjects />
        <AdminTeam />
        <AdminAwards />
        <AdminAwards2 />
      </div>
    </div>
  );
}

export default Dashboard;